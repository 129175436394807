import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Spinner, Form, Button, Input, Alert, Row, Col, Table} from 'reactstrap';
import { clientList, createClient, notify } from '../../redux/actions/user'
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loading from '../../components/Loading/Loading';


function ItemClients() {
    const dispatch = useDispatch();
    const response = useSelector( state => state.user.createClient);
    const clientsList = useSelector(state => state.user.clientList);
    const responseNotify = useSelector(state => state.user.notify);
    const [loading, setLoading] = useState(true);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [data, setData] = useState([]);
    const [id, setId] = useState();
    const location = useLocation();
    let history = useHistory();

    const [clients, setClients] = useState([]);

    useEffect(() => {
      document.title = 'BGL - Mandantes';
     
      window.scrollTo(0, 0);
    }, []);

    useEffect(()=> {
      if (history.location.state === undefined){
        return
      } else if( loading === true) {
        let data = history.location.state
        console.log(data);
        setId(data.id);
        dispatch(clientList(data.id));
      }
    },[history.location.state])

    
    useEffect(() => {
      if(response) {
        if(response.message === 'Client created') {
            setName('');
            setPhone('');
            setEmail('');
            dispatch(clientList(id));
        }
        setAlertTitle(response.status);
        setAlertType(response.status);
        setAlertMessage(response.message);
        setShowAlert(true);
      }

    },[response] );

    useEffect(() => {
        if(clientsList) {
            console.log(clientsList);
            if (clientsList.message === "Clients"){
                setData(clientsList.data)
                console.log(clientsList);
                setLoading(false);
            }
            
        }
      },[clientsList] );

      useEffect(() => {
        if(responseNotify) {
            console.log(responseNotify);
            if (responseNotify.status === 'success'){

                setLoading(false);
            }
            setAlertTitle("Noticacion");
            setAlertType(responseNotify.status);
            setAlertMessage(responseNotify.message);
            setShowAlert(true);
            
        }
      },[responseNotify] );

    const onDismiss = () => setShowAlert(false);

    const NotifyClients = () => {
        setLoading(true);

        
        dispatch(notify(id, clients));
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(createClient(id, email, name, phone));

      };

    const onClickCLient = (client) => {
        let aux = clients;
        if (aux.includes(client)) {
          aux = aux.filter(item => item !== client)
        }else {
          aux = aux.concat(client);
        }
        setClients(aux);
        console.log(aux);
        
    }

    return loading?  (
      <Loading />
    ) :(
      <>
        <Container>
          <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
            {alertTitle} - {alertMessage}
          </Alert>  
        </Container>
       
       
        <Container className="p-3 container-border" style={{marginTop: 20, marginBottom: 20}}>
        <h3>Crear Cliente para notificar</h3>
        <Form onSubmit={(e) => onFormSubmit(e)}>
        <Row>
                
                <Col>
                <Input className="col-md mt-2" placeholder="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Col>
                <Col >
                <Input className="col-md mt-2" placeholder="Nombre" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </Col>
                <Col >
                <Input className="col-md mt-2" placeholder="Telefono" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Col>
                <Col>
                <Button className="col-md">Crear</Button>
                </Col>
            </Row>
        </Form>
         <Table hover >
                <thead>
                  <tr>
                    <th>Notificar</th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Telefono</th>
                  </tr>
                </thead>
                <tbody>
                {
                    data.map((row, index) => {
                      return ( 
                            <tr key={index}>
                                <td><input  onClick={()=>{onClickCLient(row)}} type='checkbox'/></td>
                                <td>{row.data.name}</td>
                                <td>{row.data.email}</td>
                                <td>{row.data.phone}</td>
                            </tr>
                        ) 
                    }) 
                }
                </tbody>
            </Table>
            <Button onClick={() => {NotifyClients()}}>Notificar
                                        <i className="fa fa-send"></i>
            </Button>
        </Container>
      </>
    );
}
export default ItemClients;