import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  Input,
  Alert,
  Row,
  Col,
  Table,
} from "reactstrap";
import { getOffer, exportOffer } from "../../redux/actions/offer";
import { validItem } from "../../redux/actions/forms";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function OfferList() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.forms.valid);
  const offers = useSelector((state) => state.offer.get);
  const download = useSelector((state) => state.offer.download);
  const [loading, setLoading] = useState(true);
  const [numerointerno, setNumerointerno] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [page, setPage] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);
  const [patente, setPatente] = useState("");
  const [filterNI, setfilterNI] = useState("");
  const [filterName, setfilterName] = useState("");
  let history = useHistory();

  useEffect(() => {
    document.title = "BGL - Mandantes";
    dispatch(getOffer({  start: 0, end: 30 }));
  }, []);

  useEffect(() => {
    if (response) {
      console.log(response);
      if (response.data.exist === true && numerointerno !== "") {
        
        history.push({
          pathname: "/item-offer",
          state: { data: {numerointerno: numerointerno }},
        });
        setNumerointerno("");
      }
      setNumerointerno("");
      setLoading(false);
      setAlertTitle("El bien que esta buscando no existe");
      setAlertType("danger");
      setShowAlert(true);
    }
  }, [response]);

  useEffect(() => {
    if (offers) {
      console.log(offers);
      if (offers.message === "Offers") {
        setData(offers.data);
        console.log(offers);
        setLoading(false);
      }
    }
  }, [offers]);

  const goToItem = (item) => {
    console.log(item);
    
    history.push({
      pathname: "/item-offer",
      state: { data: item },
    });
  };

 

  const nextPage = () => {
    let start = (page + 1) * 30;
    setPage(page + 1);
    let end = start + 30;

    dispatch(getOffer({ start: start, end: end }));
    window.scrollTo(0, 0);
    setLoading(true);
  };

  const previousPage = () => {
    let start = (page - 1) * 30;
    setPage(page - 1);
    let end = start + 30;
    dispatch(getOffer({  start: start, end: end }));
    window.scrollTo(0, 0);
    setLoading(true);
  };

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(validItem(numerointerno, patente));
  };

  const downloadFile = () => {
    dispatch(exportOffer());
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>

      <Container
        className='container-border p-3'
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <h2>Crear Oferta</h2>
        <h6>Buscar bien por:</h6>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col>
              <Input
                className='col-md mt-2'
                placeholder='NUMERO INTERNO'
                type='text'
                value={numerointerno}
                onChange={(e) => setNumerointerno(e.target.value)}
              />
            </Col>
            <Col>
              <Input
                className='col-md mt-2'
                placeholder='PATENTE'
                type='text'
                value={patente}
                onChange={(e) => setPatente(e.target.value)}
              />
            </Col>
            <Col>
              <Button className='col-md'>Buscar</Button>
            </Col>
          </Row>
        </Form>
        
        <Container style={{ marginTop: 20, marginBottom: 20 }}>
      </Container>
      <Button
            className='float-right mb-2'
            onClick={() => {
              downloadFile();
            }}
          >
            Descargar Ofertas
          </Button>
      <h1>Lista de Ofertas</h1>
         <Table hover >
          <thead>
            <tr>
              <th>n° de oferta</th>
              <th>version</th>
              <th>bien</th>
              <th>nombre</th>
              <th>Precio</th>
              <th>Descargar</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              return   (
                <tr key={index}>
                  <td>{row.data.id}</td>
                  <td>{row.data.version ? row.data.version : '1'}</td>
                  <td>{row.data.numerointerno}</td>
                  <td>{row.data.name}</td>
                  <td>{row.data.price} {row.data.iva}</td>
                  <td>
                    {
                      <a
                        style={{ width: "100%", color: "white" }}
                        className='btn btn-secondary'
                        target='_blank'
                        href={row.data.pdf}
                      >
                        <i className='fa fa-download'></i>
                      </a>
                    }
                  </td>
                  <td>
                        <Button
                          onClick={() => {
                            goToItem(row.data);
                          }}
                          
                        >EDITAR</Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
              className='float-right m-3'
              onClick={() => {
                nextPage();
              }}
            >
              Siguiente
            </Button>
            <Button
              disabled={page <= 0 ? true : false}
              className='m-3'
              onClick={() => {
                previousPage();
              }}
            >
              Anterior
            </Button>
      </Container>
    </>
  );
}
export default OfferList;
