import { types } from '../actions/types';

export const userReducer = (state = { token: null, status: null, message: null, createClient: null, clientList: null, notify: null }, action) => {
    switch (action.type) {
        case types.userCreate:
            return {
              ...state,
              token: action.payload.token,
              status: action.payload.status,
              message: action.payload.message,
            };
        case types.userUpdate:
          return {
            ...state,
            token: action.payload.token,
            status: action.payload.status,
            message: action.payload.message,
          };
        case types.userList:
            return {
              ...state,
              token: action.payload.token,
              status: action.payload.status,
              message: action.payload.message,
              data: action.payload.data
            };
        case types.roles:
              return {
                ...state,
                roles : action.payload.roles,
                status: action.payload.status,
                message: action.payload.message
                
              };
        case types.deleteUser:
                return {
                  ...state,
                  roles : action.payload.roles,
                  status: action.payload.status,
                  message: action.payload.message
                  
                };
        case types.createClient:
          return {
            ...state,
            createClient: {
              data : action.payload.data,
              status: action.payload.status,
              message: action.payload.message
            }
          };
        case types.notify:
            return {
              ...state,
              notify: {
                data : action.payload.data,
                status: action.payload.status,
                message: action.payload.message
              }
            };
        case types.clientList:
          return {
            ...state,
            clientList: {
              data : action.payload.data,
              status: action.payload.status,
              message: action.payload.message
            }
            
          };
        default:
            return state;
    }
}