import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Spinner, Button, Label, Input, Alert, Row, Col, Table, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption } from 'reactstrap';
import { getForms } from '../../redux/actions/forms';
import { useLocation } from "react-router-dom";
import { black } from 'color-name';
import Loading from '../../components/Loading/Loading';

function ItemView() {
    const dispatch = useDispatch();
    const response = useSelector( state => state.forms);
    const [loading, setLoading] = useState(true);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [filterNI, setfilterNI] = useState('');
    const [filterMandante, setfilterMandante] = useState('');
    const [data, setData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const location = useLocation();

    useEffect(() => {
      document.title = 'BGL - Bienes';
      window.scrollTo(0, 0);

    }, []);
    

    useEffect(()=> {
        if (location.state === undefined){
          return
        } else if( loading === true) {
          let data = location.state.data
          setData(data);
          setLoading(false);          
        }
        
      })



      const onDismiss = () => setShowAlert(false);
      const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === data.img.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }

    const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? data.img.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
    }



    return loading?  (
        <Loading/>
      ) :(
        <div>
          <Container>
            <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
              {alertTitle} - {alertMessage}
            </Alert>  
          </Container>
          <h1>{data.head.ano} {data.head.marca} {data.head.modelo}</h1>
          <Container>
            <Row>
              <Col>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                >
                <CarouselIndicators items={data.img} activeIndex={activeIndex} onClickHandler={goToIndex} />


                {data.img.map((image) => {
                return (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={image}
                >
                    <img src={image}/>
                </CarouselItem>
                )})}

                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                    </Carousel>
                    </Col>
                    <Col>
                    <h3>Descripcion</h3>
                    <hr  style={{
                        color: '#000000',
                        backgroundColor: '#000000',
                        height: .5,
                        borderColor : '#000000'
                    }}/>
                  <p>VALOR: {data.head.price}  + IVA + 5% DE COMISION</p>
                  <p>SE ACEPTAN OFERTAS</p>
                  <h3>Especificaciones</h3>
                    <hr  style={{
                        color: '#000000',
                        backgroundColor: '#000000',
                        height: .5,
                        borderColor : '#000000'
                    }}/>
                <Table >
                <tbody>
                    <tr key={0}>
                        <td>Fabricante</td>
                        <td>{data.head.marca}</td>
                    </tr>
                    <tr key={1}>
                        <td>Modelo</td>
                        <td>{data.head.modelo}</td>
                    </tr>
                    <tr key={2}>
                        <td>Año</td>
                        <td>{data.head.ano}</td>
                    </tr>
                    <tr key={3}>
                        <td>Condicion</td>
                        <td>{data.mecanicState.funciona}</td>
                    </tr>

                    <tr key={4}>
                        <td>Numero de Stock</td>
                        <td>{data.head.numerointerno}</td>
                    </tr>
                    <tr key={5}>
                        <td>Estado</td>
                        <td>{data.comments}</td>
                    </tr>
                    <tr key={6}>
                        <td>Combustible</td>
                        <td>{data.mecanicState.combustible}</td>
                    </tr>
                    <tr key={7}>
                        <td>Kilometraje</td>
                        <td>{data.head.kms}</td>
                    </tr>
                </tbody>
            </Table>






                    </Col>
                    </Row>
            </Container>
        </div>
        )
};

export default ItemView;
