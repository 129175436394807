import React, { useEffect, useState } from "react";
import { Container, Label, Input, Col } from "reactstrap";

function BodyInspectionRow(props)
{
  const [check, setcheck] = useState(false);
  const [cant, setcant] = useState("");
  const [obs, setObs] = useState("");
  const onChangeObs = (e) =>
  {
    setObs(e);
    let obsUpper = e.toUpperCase();
    props.setValue({
      value: { checked: check, obs: obsUpper, quantity: cant },
      label: props.name,
    });
  };
  const onChangeCheck = (e) =>
  {
    if (check !== false)
    {
      setcheck(false);
      props.setValue({
        value: { checked: false, obs: obs, quantity: cant },
        label: props.name,
      });
      return;
    }
    setcheck(e);
    props.setValue({
      value: { checked: e, obs: obs, quantity: cant },
      label: props.name,
    });
  };
  const onChangeCant = (e) =>
  {
    setcant(e);
    props.setValue({
      value: { checked: check, obs: obs, quantity: e },
      label: props.name,
    });
  };
  useEffect(() =>
  {
    if (props.value)
    {
      setcheck(props.value.value.checked);
      setcant(props.value.value.quantity);
      setObs(props.value.value.obs);
    } else
    {
      setcheck(false);
      setcant("");
      setObs("");
    }
  }, [props.value]);

  return (
    <Container style={{ padding: "10px", marginBottom: "20px" }}>
      <Container className='col-md-6 float-left'>
        <Col sm='10' className={"float-left"}>
          <Label>{props.name}</Label>
        </Col>
        <Col sm='2' className={"float-right"}>
          <Input
            onChange={(e) =>
            {
              onChangeCheck(e.target.value);
            }}
            checked={check}
            type='checkbox'
          />
        </Col>
      </Container>
      <Container className='col-md-6 float-right'>
        <Col sm='4' className={"float-left"}>
          <Input
            onChange={(e) =>
            {
              onChangeCant(e.target.value);
            }}
            type='text'
            value={cant}
            placeholder='Cantidad'
          />
        </Col>

        <Col sm='6' className={"float-right"}>
          <Input
            style={{ textTransform: "uppercase" }}
            type='text'
            onChange={(e) =>
            {
              onChangeObs(e.target.value);
            }}
            value={obs}
            maxLength="55"
            placeholder='Observaciones'
          />
        </Col>
      </Container>
    </Container>
  );
}
export default BodyInspectionRow;
