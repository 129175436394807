import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Col} from 'reactstrap';

function FormIDRow(props) {

  const onChangeToUpper =  (e) => {
    let obs = e.target.value.toUpperCase();
    props.setValue({value: obs, label: props.name})
  }
    
    return (
      <>
        <FormGroup >
            <Label column="true" sm="3">
            {props.name}
            </Label>
            <Col sm="8"  className="float-right">
            <Input value={props.value.value}  style={{textTransform: "uppercase"}} onChange={onChangeToUpper}/>
            </Col>
        </FormGroup>
      </>
    );
}
export default FormIDRow;