import { types } from '../actions/types';

export const providerReducer = (state = { data: null, providers: null, categories: null, createProvider: null,createCategory: null,createCategoryType: null, deleteProvider: null, getStats: null, report: null }, action) => {
    switch (action.type) {
        case types.createProvider:
            return {
              ...state,
              createProvider: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

              }
              
            };
        case types.createCategory:
            return {
                ...state,
                createCategory: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

                }
                
            };
        case types.createCategoryType:
            return {
                ...state,
                createCategoryType: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

                }
                
            };
        case types.createReport:
            return {
              ...state,
              report: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

              }
              
            };
        case types.getProvider:
            return {
                ...state,
                providers: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                    
                }
                
            };
            case types.getStats:
                return {
                    ...state,
                    getStats: {
                        status: action.payload.status,
                        message: action.payload.message,
                        data: action.payload.data,
                        
                    }
                    
                };
        case types.getCategories:
            return {
                ...state,
                categories: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                }
            };
        case types.deleteProvider:
            return {
                ...state,
                deleteProvider: {
                    status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

                }
                
            };
        default:
            return state;
    }
}