import React, { useEffect, useState } from "react";
import { langs } from "../../langs/langs";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { getStats } from "../../redux/actions/provider";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function Home() {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.lang);
  const stats = useSelector((state) => state.provider.getStats);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = "BGL - Home";
    dispatch(getStats());
  }, []);

  useEffect(() => {
    if (history.location === undefined) {
      return;
    } else if (loading === true) {
      dispatch(getStats());
    }
  }, [location.state]);

  useEffect(() => {
    if (stats) {
      if (stats.message === "Providers Stats") {
        setData(stats.data);
        setLoading(false);
      }
    }
  }, [stats]);

  const goToItem = (item) => {
    history.push({
      pathname: "/items",
      filter: item,
    });
  };

  return loading ? (
    <Loading />
  ) : (
    <Container className='col-md-10'>
      {data.map((provider, index) => {
        return (
          <Card
            key={index}
            className='container-border p-3 float-left ml-3'
            style={{ height: "340px", width: "250px" }}
          >
            <CardTitle style={{ height: "55px" }} tag='h5'>
              {provider.provider} - {provider.provider_id}
            </CardTitle>
            <CardSubtitle tag='h6' className='mb-2 text-muted'>
              Total de bienes: {provider.total}
            </CardSubtitle>
            <CardBody>
              <h6>
                <span className='badge badge-info'>
                  Liberados: {provider.released}
                </span>
              </h6>
              <h6>
                <span className='badge badge-success'>
                  Vendidos: {provider.sold}
                </span>
              </h6>
              <h6>
                <span className='badge badge-danger'>
                  No liberado: {provider.not_released}
                </span>
              </h6>
              <h6>
                <span
                  style={{ color: "white" }}
                  className='badge badge-warning'
                >
                  Devueltos: {provider.returned}
                </span>
              </h6>
            </CardBody>

            <Button
              onClick={() => {
                goToItem(provider.provider);
              }}
            >
              Ver bienes
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-box-arrow-right ml-2'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z'
                />
                <path
                  fillRule='evenodd'
                  d='M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'
                />
              </svg>
            </Button>
          </Card>
        );
      })}
    </Container>
  );
}

export default Home;
