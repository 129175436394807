import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmSaveModal = (props) => {
  const { buttonLabel, className, save, suresave } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    save(true);
    setModal(!modal);
  };
  const toggleNotSave = () => {
    save(false);
    setModal(!modal);
  };
  const sureSaveToggle = () => {
    suresave(true);
    setModal(!modal);
  };

  return (
    <div>
      <Button color='success' className={"float-left"} onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal
        isOpen={modal}
        modalTransition={{ timeout: 300 }}
        backdropTransition={{ timeout: 300 }}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>Guardar</ModalHeader>
        <ModalBody>
          <p>¿Estas seguro que quieres guardas este bien? </p>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={sureSaveToggle}>
            Guardar
          </Button>{" "}
          <Button color='secondary' onClick={toggleNotSave}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmSaveModal;
