import { types, API_URL } from './types';
import Axios from 'axios';


export const createForms = (form) => { 
  const url = `${API_URL}/item/form`
  
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: form
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setCreatedForm(outputData));
      }
      else {
        outputData['data'] = data.data;
        outputData['status'] = 'success';
        outputData['message'] = data.message;
        dispatch(setCreatedForm(outputData));
      }
    })
  }
};

export const setCreatedForm = (data) => ({
    type: types.createdForm,
    payload: data,
});

export const formPhoto = (form) => { 
  const url = `${API_URL}/item/image`
  
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: form
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        
        outputData['status'] = 'danger';
        outputData['message'] = data.message;
        dispatch(setFormPhoto(outputData));
      }
      else {
        outputData['data'] = data.url;
        outputData['status'] = 'success';
        outputData['message'] = data.message;
        dispatch(setFormPhoto(outputData));
      }
    })
  }
};

export const setFormPhoto = (data) => ({
    type: types.formPhoto,
    payload: data,
});


export const getForms = (filters) => { 
  let url = `${API_URL}/item/form?`
  let filterString = '';
  if (filters) {
    Object.keys(filters).map((filter) => {
      filterString += `&${filter}=${filters[filter]}`
    })

  }

  
  url += filterString
  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then(({ data }) => {
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setForms(outputData));
      }
      else {
        outputData['status'] = 'success';
        outputData['message'] = data.message;
        outputData['data'] = data.data;
        dispatch(setForms(outputData));
      }
    })
  }
};

export const setForms = (data) => ({
    type: types.setForms,
    payload: data,
});

export const acceptForm = (form) => { 
  const url = `${API_URL}/item/form`
  
  
  return (dispatch) => {
    let outputData = {};
    form['value'] = form['value']
    let params = {
        data: form
  
    };
    Axios.put(url, params).then(({ data }) => {
      console.log(data)
      if (data.error) {
        outputData['prov'] = 'accept';
        outputData['status'] = 'danger';
        outputData['message'] = data.message;
        dispatch(setAcceptForm(outputData));
      }
      else {
        outputData['prov'] = 'accept';
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha aprobado el bien';
        dispatch(setAcceptForm(outputData));
      }
    })
  }
};

export const setAcceptForm = (data) => ({
    type: types.acceptForm,
    payload: data,
});

export const changeFormState= (form) => { 
  const url = `${API_URL}/item/status`
  
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: form
    };
    Axios.put(url, params).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setChangeFormState(outputData));
      }
      else {
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha actualizado correctamente el bien';
        outputData['data'] = data.data;
        dispatch(setChangeFormState(outputData));
      }
    })
  }
};

export const setChangeFormState = (data) => ({
    type: types.changeFormState,
    payload: data,
});


export const validItem = (numerointerno, patente) => { 
  let url = `${API_URL}/item/search`
  if (numerointerno) {
    url += `?numerointerno=${numerointerno}`
  } else if (patente) {
    url += `?patente=${patente}`   
  }
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setValidItem(outputData));
      }
      else {
        if (data.exist === true) {
          outputData['data'] = data;
          outputData['status'] = data.status;
          outputData['message'] = data.message;
          dispatch(setValidItem(outputData));
          
        } else{
          outputData['data'] = data.data;
          outputData['status'] = data.status;
          outputData['message'] = data.message;
          dispatch(setForms(outputData));
        }
       
      }
    })
  }
};

export const setValidItem = (data) => ({
    type: types.validItem,
    payload: data,
});

//clean state valid form
export const cleanValidItem = () => ({
    type: types.validItem,
    payload: {},
});

export const getItem = (numerointerno) => { 
  const url = `${API_URL}/item/form?numerointerno=${numerointerno}`
  
  
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setGetItem(outputData));
      }
      else {
        outputData['status'] = 'success';
        outputData['message'] = 'Se encontro el bien  correctamente';
        outputData['data'] = data.data;
        dispatch(setGetItem(outputData));
      }
    })
  }
};

export const setGetItem = (data) => ({
    type: types.getItem,
    payload: data,
});

export const getItemStorage = () => { 
  const url = `${API_URL}/free_limit_items`
  
  
  return (dispatch) => {
    let outputData = {};
    Axios.get(url).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setGetItemStorage(outputData));
      }
      else {
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha creado correctamente el bien';
        outputData['data'] = data.data;
        dispatch(setGetItemStorage(outputData));
      }
    })
  }
};

export const setGetItemStorage = (data) => ({
    type: types.getItemStorage,
    payload: data,
});

export const feature = (numerointerno) => { 
  const url = `${API_URL}/item/featured`
  
  
  return (dispatch) => {
    let params =  {
      data: {
      numerointerno: numerointerno
      }
    }
    let outputData = {};
    Axios.put(url, params).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setfeature(outputData));
      }
      else {
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha editado correctamente el bien';
        outputData['data'] = data.data;
        dispatch(setfeature(outputData));
      }
    })
  }
};

export const setfeature = (data) => ({
    type: types.feature,
    payload: data,
});


export const selectFirstImage = (numerointerno, imgs ) => { 
  const url = `${API_URL}/item/form/first`
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
          numerointerno: numerointerno,
          img: imgs,
        }
  
    };
    Axios.put(url, params).then(({ data }) => {
      console.log(data)
      if (data.error) {
        outputData['data'] = data;
        outputData['status'] = 'danger';
        outputData['message'] = data.message;
        dispatch(setSelectFirstImage(outputData));
      }
      else {
        outputData['data'] = data;
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha selecionado primera imagen';
        dispatch(setSelectFirstImage(outputData));
      }
    })
  }
};

export const setSelectFirstImage= (data) => ({
    type: types.selectFirst,
    payload: data,
});

export const changeImageStateForm = (numerointerno, urlB, value) => { 
  const url = `${API_URL}/item/form/image`
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
          numerointerno: numerointerno,
          url: urlB,
          value: value
        }
  
    };
    Axios.put(url, params).then(({ data }) => {
      console.log(data)
      if (data.error) {
        outputData['data'] = data;
        outputData['status'] = 'danger';
        outputData['message'] = data.message;
        dispatch(setChangeImageStateForm(outputData));
      }
      else {
        outputData['data'] = data;
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha cambiado la imagen';
        dispatch(setChangeImageStateForm(outputData));
      }
    })
  }
};

export const setChangeImageStateForm= (data) => ({
    type: types.changeImageStateForm,
    payload: data,
});


export const formFile = (numerointerno, name, fileType, file) => { 
  const url = `${API_URL}/item/form_file`
  
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
          numerointerno: numerointerno,
          file_name: name,
          file_type: fileType,
          file: file
        }
    };
    Axios.put(url, params).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setFormFile(outputData));
      }
      else {
        outputData['status'] = 'success';
        outputData['message'] = 'Se guardo correctamente el archivo';
        outputData['data'] = data;
        dispatch(setFormFile(outputData));
      }
    })
  }
};

export const setFormFile = (data) => ({
    type: types.formFile,
    payload: data,
});

export const webPageForm = (data) => { 
  const url = `${API_URL}/item/form/web`
  
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
          numerointerno: data.numerointerno,
          web: data.web,
          description: data.description,
          condition: data.condition,
          status: data.status,
        }
  
    };
    Axios.put(url, params).then(({ data }) => {
      console.log(data)
      if (data.error) {
        outputData['data'] = data;
        outputData['status'] = 'danger';
        outputData['message'] = data.message;
        dispatch(setWebPageForm(outputData));
      }
      else {
        outputData['data'] = data;
        outputData['status'] = 'success';
        outputData['message'] = 'Se ha aprobado el bien';
        dispatch(setWebPageForm(outputData));
      }
    })
  }
};

export const setWebPageForm= (data) => ({
    type: types.webPageForm,
    payload: data,
});