
import Axios from 'axios';
// Add a request interceptor

Axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.common["AUTH-TOKEN"] = token;
    return config;
});



Axios.interceptors.response.use(undefined, err => {
    const error = err.response;
    // if error is 401 
    console.log(err);
    
    if (error.status===401 && error.config && 
    !error.config.__isRetryRequest) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
    }
    return error.config
   
  });