import React from 'react';
import { connect } from 'react-redux';
import './Layout.scss';
//import Sidebar from '../components/Sidebar/Sidebar';
import { Button } from 'reactstrap';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';


const Layout = (props) => {
  return (
    <>
      <Header/>
      <div className="content">
        {props.children}
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {  };
};

export default connect(mapStateToProps)(Layout);
