import React, {useState} from 'react';
import { Collapse, Navbar, Button, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,NavbarToggler } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import './Header.scss';
import Logo from '../../assets/img/logo.jpeg'
import { logout } from '../../redux/actions/auth';

const Header = (props) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token')
  
  
  return (
    <div >
      <Navbar className="bg-white " light expand="lg">
      <NavbarToggler onClick={toggleNavbar} > </NavbarToggler>
        <NavbarBrand href="/" className="mr-auto">
          <img style={{ width: "100px" }} src={Logo}></img>
        </NavbarBrand>
        <Collapse isOpen={!collapsed} navbar>
          { !token ? <Nav  navbar className="ml-auto">
            <NavItem>
              <NavLink href="/">Sign In</NavLink>
            </NavItem>
            </Nav>
            :
             role == "admin" ? 
             <Nav  navbar className="ml-auto">
            <NavItem>
              <NavLink href="/">Inicio</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/report">Reportes</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/category">Categorias</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Usuarios
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem href="/create-user">
                  crear usuarios
                </DropdownItem>
                <DropdownItem href="/user-list">
                  lista de usuarios
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/provider">Mandantes</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/offerlist">Ofertas</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/quotationlist">Cotizacion</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Recepcion
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem href="/create-items">
                  Ingresar Bien
                </DropdownItem>
                <DropdownItem href="/list-items">
                  Revisar Actas  
                </DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/items">Bienes</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/item-storage">Bodegaje</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => {dispatch(logout());window.location = "/sign-in"}} href="/sign-in">Logout</NavLink>
            </NavItem>
          </Nav>
          : role === "vendedor" ?(
          <Nav  navbar className="ml-auto">
          <NavItem>
              <NavLink href="/">Inicio</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/report">Reportes</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/offerlist">Ofertas</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/quotationlist">Cotizacion</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Recepcion
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem href="/create-items">
                  Ingresar Bien
                </DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/items">Bienes</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/item-storage">Bodegaje</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => {dispatch(logout());window.location = "/sign-in"}} href="/sign-in">Logout</NavLink>
            </NavItem>
          </Nav>) :(
            <Nav  navbar className="ml-auto"> 
           
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Recepcion
          </DropdownToggle>
          <DropdownMenu left="true">
            <DropdownItem href="/create-items">
              Ingresar Bien
            </DropdownItem>
            <DropdownItem href="/list-items">
              Revisar Actas  
            </DropdownItem>
            <DropdownItem divider />
          </DropdownMenu>
        </UncontrolledDropdown>
        <NavItem>
          <NavLink onClick={() => {dispatch(logout());window.location = "/sign-in"}} href="/sign-in">Logout</NavLink>
        </NavItem>
          </Nav> 
          )}
        
      
        </Collapse>
      </Navbar>
    </div>
  );
};
export default Header;