import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, FormGroup, Label, Input, Col, Row } from "reactstrap";
import FormDocRow from "./FormDocRow";
import FormIDRow from "./FormIDRow";
import { getProvider, getCategories } from "../../redux/actions/provider";
import Loading from "../Loading/Loading";

function FormHeader(props)
{
  const defaultValue = { checked: false, obs: "" };
  const dispatch = useDispatch();

  const dict = {
    numerointerno: {
      value: props.isPushed
        ? props.value.numerointerno === undefined
          ? ""
          : props.value.numerointerno.value
        : "",
      label: "Numero Interno",
    },
    mandante: {
      value: props.isPushed
        ? props.value.mandante === undefined
          ? ""
          : props.value.mandante.value
        : "",
      label: "Mandante",
    },
    categoria: {
      value: props.isPushed
        ? props.value.categoria === undefined
          ? ""
          : props.value.categoria.value
        : "",
      label: "Categoria del bien",
      types_: [],
    },
    tipo: {
      value: props.isPushed
        ? props.value.tipo === undefined
          ? ""
          : props.value.tipo.value
        : "",
      label: "Tipo del bien",
    },
    marca: {
      value: props.isPushed
        ? props.value.marca === undefined
          ? ""
          : props.value.marca.value
        : "",
      label: "Marca",
    },
    modelo: {
      value: props.isPushed
        ? props.value.modelo === undefined
          ? ""
          : props.value.modelo.value
        : "",
      label: "Modelo",
    },
    ano: {
      value: props.isPushed
        ? props.value.ano === undefined
          ? ""
          : props.value.ano.value
        : "",
      label: "Año",
    },
    color: {
      value: props.isPushed
        ? props.value.color === undefined
          ? ""
          : props.value.color.value
        : "",
      label: "Color",
    },
    motor: {
      value: props.isPushed
        ? props.value.motor === undefined
          ? ""
          : props.value.motor.value
        : "",
      label: "Motor",
    },
    chasis: {
      value: props.isPushed
        ? props.value.chasis === undefined
          ? ""
          : props.value.chasis.value
        : "",
      label: "Chasis",
    },
    kms: {
      value: props.isPushed
        ? props.value.kms === undefined
          ? ""
          : props.value.kms.value
        : "",
      label: "Kilometraje",
    },
    horas: {
      value: props.isPushed
        ? props.value.horas === undefined
          ? ""
          : props.value.horas.value
        : "",
      label: "Horas",
    },
    patente: {
      value: props.isPushed
        ? props.value.patente === undefined
          ? ""
          : props.value.patente.value
        : "",
      label: "Patente",
    },
    permiso: {
      value: props.isPushed
        ? props.value.permiso === undefined
          ? ""
          : props.value.permiso.value
        : defaultValue,
      label: "Permiso de circulacion",
    },
    revision: {
      value: props.isPushed
        ? props.value.revision === undefined
          ? ""
          : props.value.revision.value
        : defaultValue,
      label: "Revisión Técnica",
    },
    certificacion: {
      value: props.isPushed
        ? props.value.certificacion === undefined
          ? ""
          : props.value.certificacion.value
        : defaultValue,
      label: "Certificación de gases",
    },
    seguro: {
      value: props.isPushed
        ? props.value.seguro === undefined
          ? ""
          : props.value.seguro.value
        : defaultValue,
      label: "Seguro obligatorio",
    },
    codigo: {
      value: props.isPushed
        ? props.value.codigo === undefined
          ? ""
          : props.value.codigo.value
        : "",
      label: "Número de operacion",
    },
  };


  const [numerointerno, setnumerointerno] = useState(dict.numerointerno);
  const [mandante, setmandante] = useState(dict.mandante);
  const [categoria, setcategoria] = useState(dict.categoria);
  const [tipo, settipo] = useState(dict.tipo);
  const [marca, setmarca] = useState(dict.marca);
  const [modelo, setmodelo] = useState(dict.modelo);
  const [ano, setano] = useState(dict.ano);
  const [color, setcolor] = useState(dict.color);
  const [patente, setpatente] = useState(dict.patente);
  const [motor, setmotor] = useState(dict.motor);
  const [chasis, setchasis] = useState(dict.chasis);
  const [kms, setkms] = useState(dict.kms);
  const [horas, sethoras] = useState(dict.horas);
  const [permiso, setpermiso] = useState(dict.permiso);
  const [revision, setrevision] = useState(dict.revision);
  const [certificacion, setcertificacion] = useState(dict.certificacion);
  const [seguro, setseguro] = useState(dict.seguro);
  const [codigo, setcodigo] = useState(dict.codigo);
  const providers = useSelector((state) => state.provider.providers);
  const categories = useSelector((state) => state.provider.categories);
  const [loading, setLoading] = useState(true);
  const [loadingC, setLoadingC] = useState(true);

  useEffect(() =>
  {
    document.title = "BGL - Ingreso de Bienes";
    dispatch(getProvider());
    dispatch(getCategories());
  }, []);

  useEffect(() =>
  {
    if (props.isPushed)
    {

      setnumerointerno(dict.numerointerno);
      setmandante(dict.mandante);
      setcategoria(dict.categoria);
      settipo(dict.tipo);
      setmarca(dict.marca);
      setmodelo(dict.modelo);
      setano(dict.ano);
      setcolor(dict.color);
      setpatente(dict.patente);
      setmotor(dict.motor);
      setchasis(dict.chasis);
      setkms(dict.kms);
      setpatente(dict.patente);
      sethoras(dict.horas);
      setpermiso(dict.permiso);
      setrevision(dict.revision);
      setcertificacion(dict.certificacion);
      setseguro(dict.seguro);
      setcodigo(dict.codigo);
    }
  }, [props.isPushed]);

  useEffect(() =>
  {
    if (props.save === true)
    {
      props.setValue(getJSON());
    }
  }, [props.save]);

  useEffect(() =>
  {
    if (providers)
    {
      if (providers.message === "Providers")
      {
        if (props.isPushed && props.value !== {})
        {
          setmandante({ value: props.value.mandante.value, label: mandante.label });
        }
        setLoading(false);
      }
    }
  }, [providers]);

  useEffect(() =>
  {
    if (categories)
    {
      if (categories.message === "Categories")
      {


        if (props.isPushed && props.value !== {})
        {
          setTypes({ target: { value: props.value.categoria.value }, update: true });
        }
      }
    }
  }, [categories]);

  const getJSON = () =>
  {
    let dict = {
      numerointerno: numerointerno,
      mandante: mandante,
      categoria: categoria,
      tipo: tipo,
      marca: marca,
      modelo: modelo,
      ano: ano,
      color: color,
      patente: patente,
      motor: motor,
      chasis: chasis,
      kms: kms,
      horas: horas,
      permiso: permiso,
      revision: revision,
      certificacion: certificacion,
      seguro: seguro,
      codigo: codigo,
    };
    return dict;
  };

  const setTypes = (e) =>
  {
    let name = e.target.value;
    let types_ = categories.data.data.filter(
      (category) => category.category_name === name
    )[0];
    setcategoria({
      value: e.target.value,
      label: categoria.label,
      types_: types_.types,
    });
    if (e.update)
    {
      settipo({
        value: props.value.tipo.value,
        label: tipo.label
      })
    }


    setLoadingC(false);
  };

  return loading && loadingC ? (
    <Loading />
  ) : (
    <Container style={{ marginTop: "10px", height: "750px" }}>
      <Container className={"col-md-12"}>
        <FormGroup className='col-md-12 float-left'>
          <Row>
            <Label column='true' sm='4'>
              Mandante
            </Label>
            <Col>
              <Input
                type='select'
                value={mandante.value}
                onChange={(e) =>
                {
                  setmandante({ value: e.target.value, label: mandante.label });
                }}
              >
                <option value={""}>{""}</option>
                {providers &&
                  providers.data.map((provider, index) =>
                    provider.name !== mandante.value ||
                      mandante.value !== "" ? (
                      <option key={index} value={provider.name}>
                        {provider.name}
                      </option>
                    ) : (
                      <option key={index} selected value={mandante.value}>
                        {mandante.value}
                      </option>
                    )
                  )}
              </Input>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Label column='true' sm='4'>
              Categoria del Bien
            </Label>
            <Col sm='8' className='float-right'>
              <Input
                type='select'
                onChange={(e) => setTypes(e)}
                value={categoria.value}
              >
                <option value={""}>{""}</option>
                {categories &&
                  categories.data.data.map((category, index) => (
                    <option key={index} value={category.category_name}>
                      {category.category_name}
                    </option>
                  ))}
              </Input>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Label column='true' sm='4'>
              Tipo del Bien
            </Label>
            <Col sm='8' className='float-right'>
              <Input
                value={tipo.value}
                type='select'
                onChange={(e) =>
                  settipo({ value: e.target.value, label: tipo.label })
                }
              >
                <option value={""}>{""}</option>
                {categoria.types_ &&
                  categoria.types_.map((type, index) => (
                    <option key={index} value={type.name}>
                      {type.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </Row>
        </FormGroup>
      </Container>

      <Container className={"col-md-5 float-left"}>
        <h3>Identificacion del Bien</h3>
        <FormIDRow name='Marca' value={marca} setValue={setmarca} />
        <FormIDRow name='Modelo' value={modelo} setValue={setmodelo} />
        <FormIDRow name='Año' value={ano} setValue={setano} />
        <FormIDRow name='Color' value={color} setValue={setcolor} />
        <FormIDRow name='Patente' value={patente} setValue={setpatente} />
        <FormIDRow name='Motor' value={motor} setValue={setmotor} />
        <FormIDRow name='Chasis' value={chasis} setValue={setchasis} />
        <FormIDRow name='KMS' value={kms} setValue={setkms} />
        <FormIDRow name='Horas' value={horas} setValue={sethoras} />
        <FormIDRow name='Nº operación' value={codigo} setValue={setcodigo} />
      </Container>
      {props.many === false ? (
        <Container className={"col-md-7 float-left"}>
          <h3>Documentos</h3>
          <FormDocRow
            name='Permiso de circulacion'
            value={permiso}
            setValue={setpermiso}
          />
          <FormDocRow
            name='Revisión Técnica'
            value={revision}
            setValue={setrevision}
          />
          <FormDocRow
            name='Certificación Gases'
            value={certificacion}
            setValue={setcertificacion}
          />
          <FormDocRow
            name='Seguro obligatorio'
            value={seguro}
            setValue={setseguro}
          />

        </Container>
      ) : (
        <></>
      )}
    </Container>
  );
}
export default FormHeader;
