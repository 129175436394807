import React, { Component } from 'react';
import { connect} from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import './App.scss';
import Layout from './layout/Layout';
import Home from './pages/Home/Home';
import SignIn from './pages/SignIn/SignIn';
import Logout from './pages/Logout/Logout';
import {authCheckState} from './redux/actions/auth';
import UserCreate from './pages/User/UserCreate';
import './redux/actions/index';
import UserList from './pages/User/UserList';
import ItemForm from './pages/Items/ItemForm';
import ListItems from './pages/Items/ListItems';
import Provider from './pages/Provider/Provider';
import GridItems from './pages/Items/GridItems';
import ItemView from './pages/Items/ItemView';
import Item from './pages/Items/Item';
import ItemClients from './pages/Items/ItemClients';
import CreateQuotation from './pages/Quotation/CreateQuotation';
import CreateOffer from './pages/Offer/CreateOffer';
import OfferList from './pages/Offer/OfferList';
import QuotationList from './pages/Quotation/QuotationList';
import ImageItem from './pages/Items/ImageItem';
import ItemFile from './pages/Items/ItemFile';
import TakePhoto from './components/Item/TakePhoto';
import Report from './pages/Reports/Report';
import Category from './pages/Category/Category';
import ItemStorage from './pages/Items/ItemStorage';


class App extends Component {
  state = {};
  
  componentDidMount() {
    this.props.onTryAutoSignup();
    console.log('esta logeado: ', this.props.isAuthenticated);

  }
  render(){
    let routes = (
      <Switch>
        <Route path="/" component={SignIn} />
      </Switch>
    );
    
    if (this.props.isAuthenticated) {
      
      let role = localStorage.getItem('role');
      
      if (role ==="admin"){
        routes = (
          <Switch>
            <Route  exact path="/" component={Home} />
            <Route path="/create-user" component={UserCreate} />
            <Route path="/user-list" component={UserList} />
            <Route path="/create-items" component={ItemForm} />
            <Route path="/list-items" component={ListItems} />
            <Route path="/provider" component={Provider} />
            <Route path="/items" component={GridItems} />
            <Route path="/item" component={ItemView} />
            <Route path="/item-admin" component={Item} />
            <Route path="/item-client" component={ItemClients} />
            <Route path="/item-quotation" component={CreateQuotation} />
            <Route path="/quotationlist" component={QuotationList} />
            <Route path="/item-offer" component={CreateOffer} />
            <Route path="/offerlist" component={OfferList} />
            <Route path="/item-image" component={ImageItem} />
            <Route path="/item-file" component={ItemFile} />
            <Route path="/item-photo" component={TakePhoto} />
            <Route path="/report" component={Report} />
            <Route path="/category" component={Category} />
            <Route path="/item-storage" component={ItemStorage} />
            <Route path="/sign-in" >
              <Redirect to="/" />
            </Route>
            <Route path="/logout" component={Logout} />
            
  
  
          </Switch>
        )

      }else if (role ==="operador"){
        routes = (
          <Switch>
            <Route path="/create-items" component={ItemForm} />
            <Route path="/list-items" component={ListItems} />
            <Route path="/item-photo" component={TakePhoto} />
            <Route path="/sign-in" >
              <Redirect to="/create-items" />
            </Route>
            <Route path="/" >
              <Redirect to="/create-items" />
            </Route>
            <Route path="/logout" component={Logout} />
            
          </Switch>
        )

      }else if (role ==="vendedor"){
        routes = (
          <Switch>
            <Route  exact path="/" component={Home} />
            <Route path="/create-items" component={ItemForm} />
            <Route path="/list-items" component={ListItems} />
            <Route path="/items" component={GridItems} />
            <Route path="/item" component={ItemView} />
            <Route path="/item-admin" component={Item} />
            <Route path="/item-client" component={ItemClients} />
            <Route path="/item-quotation" component={CreateQuotation} />
            <Route path="/quotationlist" component={QuotationList} />
            <Route path="/item-offer" component={CreateOffer} />
            <Route path="/offerlist" component={OfferList} />
            <Route path="/item-image" component={ImageItem} />
            <Route path="/item-file" component={ItemFile} />
            <Route path="/item-photo" component={TakePhoto} />
            <Route path="/item-storage" component={ItemStorage} />
            <Route path="/report" component={Report} />
            <Route path="/sign-in" >
              <Redirect to="/" />
            </Route>
            <Route path="/logout" component={Logout} />
          </Switch>
        )

      }
      
       
    }

    return <Layout>{ routes }</Layout>;
  }
}

const mapStateToProps = (state) => {
    return {
      isAuthenticated: localStorage.getItem('token') !== null 
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(authCheckState()),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

function NoMatch() {
  return (
    <div>
      <h3>
        404 - Not Found
      </h3>
    </div>
  );
}