import React, { useEffect, useState, useDebugValue } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Spinner, Button, Label, Input, Alert, Row, Col,
    FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {   webPageForm} from '../../redux/actions/forms';
import Loading from '../Loading/Loading';

function ModalWebPage(props) {
    const dispatch = useDispatch();
    const response = useSelector( state => state.forms.web);
    const [loading, setLoading] = useState(false);
    const [description,setDescription] = useState("");
    const [web, setWeb] = useState(props.data.web ? props.data.web.show : false);
    const [modal, setModal] = useState(props.modal);
    const [data, setData] = useState(props.data);
    let history = useHistory();

      useEffect(() => {

          if (response && loading ) {
              setData(response.data.data)
              let state = response.data.data;
              history.replace({ ...history.location, state});
              setLoading(false)
          }
        }, [response]);

    const modalBody = () => {

            return (
            <ModalBody>
                 <FormGroup check>
                <Label check>
                    <Input className="mt-3" type="radio"   defaultChecked={web} name="web" onClick={(e) => {setWeb(!web)}} checked={web} />
                <h5>Pagina Web</h5>
                </Label>
                </FormGroup>
                <FormGroup>
                    <Label for="desc">Descripcion para pagina web</Label>
                    <Input type="textarea" name="desc" id="desc" placeholder="Descripcion" onChange={(e) => setDescription(e.target.value)} value={description} />
                </FormGroup>
            </ModalBody>)
            
    }
        
    const toggle = () => {

        setModal(!modal);

    }
    const saveModal = () => {
       
        
        let dict = {
                web: web,
                numerointerno: data.head.numerointerno.value,
                description: description,
        }
        console.log(dict);
        dispatch(webPageForm(dict))
        setLoading(true)
        setModal(!modal);
    }

    return loading?  (
        <Loading />
      ) :(
        <div>
        <Button  style={{width:'100%'}} color="secondary" onClick={() => toggle()}>Mostrar en Pagina Web</Button>
          <Modal isOpen={modal} fade={false} toggle={toggle} >
            <ModalHeader toggle={toggle}>Mostrar en Pagina Web</ModalHeader>
            {modalBody()}
            <ModalFooter>
                <Button color="primary" onClick={saveModal}>Guardar</Button>
                <Button color="secondary" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
            </Modal>
            </div>
            )
};

export default ModalWebPage;