import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import BodyInspectionRow from "./BodyInspectionRow";

function AccesoriesInventory(props) {
  let defaultValue = { value: { checked: false, quantity: "", obs: "" } };
  const [llaves, setllaves] = useState(defaultValue);
  const [triangulos, settriangulos] = useState(defaultValue);
  const [gata, setgata] = useState(defaultValue);
  const [llaveRuedas, setllaveRuedas] = useState(defaultValue);
  const [herramientas, setherramientas] = useState(defaultValue);
  const [extintor, setextintor] = useState(defaultValue);
  const [botiquin, setbotiquin] = useState(defaultValue);
  const [encendedor, setencendedor] = useState(defaultValue);
  const [antena, setantena] = useState(defaultValue);
  const [parrilla, setparrilla] = useState(defaultValue);
  const [viseras, setviseras] = useState(defaultValue);
  const [alarma, setalarma] = useState(defaultValue);
  const [cinturones, setcinturones] = useState(defaultValue);
  const [pisos, setpisos] = useState(defaultValue);
  const [neblineros, setneblineros] = useState(defaultValue);
  const [espejo, setespejo] = useState(defaultValue);
  const [cenicero, setcenicero] = useState(defaultValue);
  const [radio, setradio] = useState(defaultValue);
  const [parlantes, setparlantes] = useState(defaultValue);
  const [tapasEst, settapasEst] = useState(defaultValue);
  const [ac, setac] = useState(defaultValue);
  const [dirH, setdirH] = useState(defaultValue);
  const [tapasRuedas, settapasRuedas] = useState(defaultValue);
  const [plumillas, setplumillas] = useState(defaultValue);
  const [llantas, setllantas] = useState(defaultValue);
  const [neumaticos, setneumaticos] = useState(defaultValue);
  const [neumaticosRepuesto, setneumaticosRepuesto] = useState(defaultValue);
  const [tercerEje, settercerEje] = useState(defaultValue);
  const [quintaRueda, setquintaRueda] = useState(defaultValue);
  const [bateria, setbateria] = useState(defaultValue);
  const [tapasVarillas, settapasVarillas] = useState(defaultValue);
  const [espejosExt, setespejosExt] = useState(defaultValue);
  const [focosDelanteros, setfocosDelanteros] = useState(defaultValue);
  const [focosTraseros, setfocosTraseros] = useState(defaultValue);
  const [otros, setotros] = useState(defaultValue);

  useEffect(() => {
    if (props.save === true) {
      props.setValue(getJSON());
    }
  }, [props.save]);

  useEffect(() => {
    if (props.isPushed) {
      let data = props.value;
      setllaves(data.llaves);
      settriangulos(data.triangulos);
      setgata(data.gata);
      setllaveRuedas(data.llaveRuedas);
      setherramientas(data.herramientas);
      setextintor(data.extintor);
      setbotiquin(data.botiquin);
      setencendedor(data.encendedor);
      setantena(data.antena);
      setparrilla(data.parrilla);
      setviseras(data.viseras);
      setalarma(data.alarma);
      setcinturones(data.cinturones);
      setpisos(data.pisos);
      setneblineros(data.neblineros);
      setespejo(data.espejo);
      setcenicero(data.cenicero);
      setradio(data.radio);
      setparlantes(data.parlantes);
      settapasRuedas(data.tapasRuedas);
      settapasEst(data.tapasEst);
      setac(data.ac);
      setdirH(data.dirH);
      setplumillas(data.plumillas);
      setllantas(data.llantas);
      setneumaticosRepuesto(data.neumaticosRepuesto);
      setneumaticos(data.neumaticos);
      settercerEje(data.tercerEje);
      setquintaRueda(data.quintaRueda);
      setbateria(data.bateria);
      settapasVarillas(data.tapasVarillas);
      setespejosExt(data.espejosExt);
      setfocosTraseros(data.focosTraseros);
      setfocosDelanteros(data.focosDelanteros);
      setotros(data.otros);
    }
  }, [props.isPushed]);

  const getJSON = () => {
    let dict = {
      llaves: llaves,
      triangulos: triangulos,
      gata: gata,
      llaveRuedas: llaveRuedas,
      herramientas: herramientas,
      extintor: extintor,
      botiquin: botiquin,
      encendedor: encendedor,
      antena: antena,
      parrilla: parrilla,
      viseras: viseras,
      alarma: alarma,
      cinturones: cinturones,
      pisos: pisos,
      neblineros: neblineros,
      espejo: espejo,
      cenicero: cenicero,
      radio: radio,
      parlantes: parlantes,
      tapasEst: tapasEst,
      ac: ac,
      dirH: dirH,
      tapasRuedas: tapasRuedas,
      plumillas: plumillas,
      llantas: llantas,
      neumaticosRepuesto: neumaticosRepuesto,
      neumaticos: neumaticos,
      tercerEje: tercerEje,
      quintaRueda: quintaRueda,
      bateria: bateria,
      tapasVarillas: tapasVarillas,
      espejosExt: espejosExt,
      focosTraseros: focosTraseros,
      focosDelanteros: focosDelanteros,
      otros: otros,
    };
    return dict;
  };

  return (
    <Container
      className='container-border p-3 mt-2 col-md-12'
      style={{ paddingBottom: 100 }}
    >
      <h3>Accesorios e Inventario</h3>
      <BodyInspectionRow name='Llaves' value={llaves} setValue={setllaves} />
      <BodyInspectionRow
        name='Triangulos'
        value={triangulos}
        setValue={settriangulos}
      />
      <BodyInspectionRow name='Gata Ruedas' value={gata} setValue={setgata} />
      <BodyInspectionRow
        name='LLave Ruedas'
        value={llaveRuedas}
        setValue={setllaveRuedas}
      />
      <BodyInspectionRow
        name='Herramientas'
        value={herramientas}
        setValue={setherramientas}
      />
      <BodyInspectionRow
        name='Extintor'
        value={extintor}
        setValue={setextintor}
      />
      <BodyInspectionRow
        name='Botiquin'
        value={botiquin}
        setValue={setbotiquin}
      />
      <BodyInspectionRow
        name='Encendedor'
        value={encendedor}
        setValue={setencendedor}
      />
      <BodyInspectionRow name='Antena' value={antena} setValue={setantena} />
      <BodyInspectionRow
        name='Parrilla'
        value={parrilla}
        setValue={setparrilla}
      />
      <BodyInspectionRow name='Viseras' value={viseras} setValue={setviseras} />
      <BodyInspectionRow name='Alarma' value={alarma} setValue={setalarma} />
      <BodyInspectionRow
        name='Cinturones'
        value={cinturones}
        setValue={setcinturones}
      />
      <BodyInspectionRow
        name='Pisos de goma'
        value={pisos}
        setValue={setpisos}
      />
      <BodyInspectionRow
        name='Neblineros'
        value={neblineros}
        setValue={setneblineros}
      />
      <BodyInspectionRow
        name='Espejo Interior'
        value={espejo}
        setValue={setespejo}
      />
      <BodyInspectionRow
        name='Cenicero'
        value={cenicero}
        setValue={setcenicero}
      />
      <BodyInspectionRow name='Radio' value={radio} setValue={setradio} />
      <BodyInspectionRow
        name='Parlantes'
        value={parlantes}
        setValue={setparlantes}
      />
      <BodyInspectionRow
        name='Tapas Est. Comb'
        value={tapasEst}
        setValue={settapasEst}
      />
      <BodyInspectionRow name='A/C' value={ac} setValue={setac} />
      <BodyInspectionRow
        name='Dir. Hidráulica'
        value={dirH}
        setValue={setdirH}
      />
      <BodyInspectionRow
        name='Tapas Ruedas'
        value={tapasRuedas}
        setValue={settapasRuedas}
      />
      <BodyInspectionRow
        name='Plumillas'
        value={plumillas}
        setValue={setplumillas}
      />
      <BodyInspectionRow name='Llantas' value={llantas} setValue={setllantas} />
      <BodyInspectionRow
        name='Neumáticos'
        value={neumaticos}
        setValue={setneumaticos}
      />
      <BodyInspectionRow
        name='Neumático Respuesto'
        value={neumaticosRepuesto}
        setValue={setneumaticosRepuesto}
      />
      <BodyInspectionRow
        name='Tercer eje'
        value={tercerEje}
        setValue={settercerEje}
      />
      <BodyInspectionRow
        name='Quinta Rueda'
        value={quintaRueda}
        setValue={setquintaRueda}
      />
      <BodyInspectionRow name='Batería' value={bateria} setValue={setbateria} />
      <BodyInspectionRow
        name='Tapas y varillas'
        value={tapasVarillas}
        setValue={settapasVarillas}
      />
      <BodyInspectionRow
        name='Espejos Ext.'
        value={espejosExt}
        setValue={setespejosExt}
      />
      <BodyInspectionRow
        name='Focos delanteros'
        value={focosDelanteros}
        setValue={setfocosDelanteros}
      />
      <BodyInspectionRow
        name='Focos Traseros'
        value={focosTraseros}
        setValue={setfocosTraseros}
      />
      <BodyInspectionRow name='Otros' value={otros} setValue={setotros} />
    </Container>
  );
}
export default AccesoriesInventory;
