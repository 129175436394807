import React from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Webcam from "react-webcam";





function TakeImages(props)
{
  function handleTakePhoto(dataUri)
  {
    props.addImg({ web: true, data: dataUri });
    console.log(dataUri);
    
  }
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc);
      props.addImg({ web: true, data: imageSrc });
      
    },
    [webcamRef]
  );
  const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: "environment"
  };
  

  return (
    <div className="center">
    <Webcam
      audio={false}
      height={720}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      width={720}
      mirrored={false}
      videoConstraints={videoConstraints}
    />
    <button className="btn btn-success col-md-12 w-100" onClick={capture}>Tomar Foto</button>
  </div>

  );
}

export default TakeImages;