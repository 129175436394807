import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Spinner, Button, Label, Input, Alert, Row, Col,
    FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changeFormState } from '../../redux/actions/forms';
import Loading from '../Loading/Loading';
import { getLastKey } from '../../utils/utils';

function ModalItemState(props) {
    const dispatch = useDispatch();
    const response = useSelector( state => state.forms.change);
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState("");
    const [obs,setObs] = useState("");
    const [iva, setIva] = useState("");
    const [comission, setcomission] = useState("");
    const [modal, setModal] = useState(props.modal);
    const [modalType, setModalType] = useState(props.modalType);
    const [data, setData] = useState(props.data);
    const [disabled, setdisabled] = useState(props.disabled);
    const [nameB, setnameB] = useState({"Devuelto":"Devolver", "Vendido": "Vender" , "Liberado": "Liberar", "No Liberado": "No Liberar", "Cambiar": "Cambiar Precio" });
    
    useEffect(() => {
        setData(props.data);
    }, [props.data])

    let history = useHistory();

      useEffect(() => {

          if (response && loading) {
              setData(response.data)
              let state = response.data;
              history.replace({ ...history.location, state});
              console.log(state);
              console.log(history.location);
              setLoading(false)
          }
        }, [response]);

        const setIVA = (e) => {
            console.log(e.target.id);
            if (e.target.id === 'con'){
                setIva('CON IVA')
            } else if (e.target.id === 'mas'){
                setIva('+ IVA')
            }else {
                setIva('EXENTO DE  IVA')
            }
        }
        
    const modalBody = () => {
        if (modalType === "Liberado"){
            return (
            <ModalBody>
                <FormGroup check>
                    <Label for="price">Precio</Label>
                    <Input type="text" name="price" id="number" placeholder="Precio" onChange={(e) => setPrice(e.target.value)} value={price}/>
                    <Label for="porcentaje">Porcentaje de comision</Label>
                    <Input type="number" name="porcentaje" id="porcentaje" placeholder="Porcentaje" onChange={(e) => setcomission(e.target.value)} value={comission} />    
                    </FormGroup>
                <FormGroup tag="fieldset">
                <legend>IVA</legend>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="mas"  onChange={(e) => setIVA(e)}/>
                    Mas IVA
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="con" onChange={(e) => setIVA(e)} />
                   Con IVA
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="exento"  onChange={(e) => setIVA(e)}/>
                    Exento de IVA
                </Label>
                </FormGroup>
                </FormGroup>
                <Label for="obs">Descripcion de Precio</Label>
                    <Input type="textarea" name="obs" id="obs" placeholder=" Observacion" onChange={(e) => setObs(e.target.value)} value={obs} />
                    
            </ModalBody>)

        } else if ( modalType === "Vendido") {
            return(
            <ModalBody>
                <FormGroup check>
                    <Label for="price">Precio</Label>
                    <Input type="number" name="price" id="number" placeholder="Precio" onChange={(e) => setPrice(e.target.value)} value={price}/>
                    <Label for="porcentaje">Porcentaje de comision</Label>
                    <Input type="number" name="porcentaje" id="porcentaje" placeholder="Porcentaje" onChange={(e) => setcomission(e.target.value)} value={comission} />
                    </FormGroup>
                <FormGroup tag="fieldset">
                <legend>IVA</legend>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="mas"  onChange={(e) => setIVA(e)}/>
                    Mas IVA
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="con" onChange={(e) => setIVA(e)} />
                   Con IVA
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="exento"  onChange={(e) => setIVA(e)}/>
                    Exento de IVA
                </Label>
                </FormGroup>
                </FormGroup>
                <Label for="obs">Observacion</Label>
                    <Input type="text" name="obs" id="obs" placeholder="Observacion" onChange={(e) => setObs(e.target.value)} value={obs} />
            </ModalBody>)

        }else if (modalType === "Devuelto"){
            return(
            <ModalBody>
                <FormGroup>
                    <Label for="obs">Observacion</Label>
                    <Input type="text" name="obs" id="obs" placeholder="Observacion" onChange={(e) => setObs(e.target.value)} value={obs} />
                </FormGroup>
            </ModalBody>)

            
        } 
    else if (modalType === "No Liberado"){
        return(
        <ModalBody>
            <FormGroup>
                <Label for="obs">Observacion</Label>
                <Input type="text" name="obs" id="obs" placeholder="Observacion" onChange={(e) => setObs(e.target.value)} value={obs} />
            </FormGroup>
        </ModalBody>)

        
    }
    else if (modalType === "Cambiar"){
        return(
            <ModalBody>
                <FormGroup check>
                    <Label for="price">Precio</Label>
                    <Input type="text" name="price" id="number" placeholder="Precio" onChange={(e) => setPrice(e.target.value)} value={price}/>
                    <Label for="porcentaje">Porcentaje de comision</Label>
                    <Input type="number" name="porcentaje" id="porcentaje" placeholder={data.comission + "%"} onChange={(e) => setcomission(e.target.value)} value={comission} />    
                    </FormGroup>
                <FormGroup tag="fieldset">
                <legend>IVA</legend>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="mas"  onChange={(e) => setIVA(e)}/>
                    Mas IVA
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="con" onChange={(e) => setIVA(e)} />
                   Con IVA
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="radio" name="radio" id="exento"  onChange={(e) => setIVA(e)}/>
                    Exento de IVA
                </Label>
                </FormGroup>
                </FormGroup>
                <Label for="obs">Descripcion de Precio</Label>
                    <Input type="textarea" name="obs" id="obs" placeholder=" Observacion" onChange={(e) => setObs(e.target.value)} value={obs} />
                    
            </ModalBody>
        )
    } 
    }
        
    const toggle = (name) => {
        console.log(disabled);
        
        if (typeof(name) === typeof ""){
            setModalType(name)
        }
        setModal(!modal);

    }
    const saveModal = () => {
        const priceChangeModals = ["Liberado", "Vendido", "Cambiar"]
        const noPriceChangeModals = ["No Liberado", "Devuelto"]
        let currency = new Intl.NumberFormat("de-DE").format(price)
        let key = new Date().toISOString()
        if (priceChangeModals.includes(modalType)) {
            if (modalType == "Cambiar"){
                let dict = {
                    key: key,
                    status: {
                    },
                    numerointerno: data.head.numerointerno.value,
                    price: {
                        [key]: {
                            price: currency,
                            iva: iva,
                            comission: comission
                        }
                    }
                }
                if (typeof data.status === "object"){
                    dict.status = {
                        [key]: {        
                            status: data.status[getLastKey(data.status)].status,
                            observation: obs
                        }
                    }
                }
                else {
                    dict.status = {
                        [key]: {
                            status: data.status,
                            observation: obs
                        }
                    }
                }
                console.log("Dict: ", dict)
                dispatch(changeFormState(dict))
                setLoading(true)
                setModal(!modal);
            }
            else {
                let dict = {
                    key: key,
                    status: {
                        [key]: {
                            status: modalType,
                            observation: obs
                        }
                    },
                    numerointerno: data.head.numerointerno.value,
                    price: {
                        [key]: {
                            price: currency,
                            iva: iva,
                            comission: comission
                        }
                    }
                }
                console.log("Dict: ", dict)
                dispatch(changeFormState(dict))
                setLoading(true)
                setModal(!modal);
            }
        }
        else if (noPriceChangeModals.includes(modalType)) {
            let dict = {
                key: key,
                status: {
                    [key]: {
                        status: modalType,
                        observation: obs
                    }
                },
                numerointerno: data.head.numerointerno.value,
            }
            console.log("Dict: ", dict)
            dispatch(changeFormState(dict))
            setLoading(true)
            setModal(!modal);
        }
    }

    return loading?  (
        <Loading />
      ) :(
        <div>
        <Button  style={{width:'100%'}} color="success" onClick={() => toggle(modalType)} disabled={disabled}>{nameB[modalType]}</Button>
          <Modal isOpen={modal} fade={false} toggle={toggle} >
            <ModalHeader toggle={toggle}>{nameB[modalType]}</ModalHeader>
            {modalBody()}
            <ModalFooter>
                <Button color="primary" onClick={saveModal}>Guardar</Button>
                <Button color="secondary" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
            </Modal>
            </div>
            )
};

export default ModalItemState;