import { types, API_URL } from './types';
import Axios from 'axios';


export const createQuotation = (numerointerno, sirs, rut, contact, email, phone, obs, price, iva, comission, name, version) => { 
  const url = `${API_URL}/item/quotation`
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
            
          numerointerno: numerointerno,
          sirs: sirs,
          rut: rut,
          contact:contact,
          email: email, 
          phone: phone, 
          obs: obs,
          price:price,
          iva: iva,
          comission: comission,
          name : name,
          version: version,
        }
      
    };
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setCreateQuotation(outputData));
      }
      else {

        outputData['data'] = data.url
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        dispatch(setCreateQuotation(outputData));
      }
    })
  }
};

export const setCreateQuotation = (data) => ({
    type: types.createQuotation,
    payload: data,
});

export const getQuotation = (filters) => { 
    let url = `${API_URL}/item/quotation?`
    let filterString = '';
    if (filters) {
      Object.keys(filters).map((filter) => {
        console.log(filter);
        filterString += `&${filter}=${filters[filter]}`
      })
  
    }
    console.log(filterString);
    
    url += filterString
    
    
    return (dispatch) => {
      let outputData = {};
    
      Axios.get(url).then(({ data }) => {
        if (data.error) {
          outputData['status'] = 'danger';
          outputData['message'] = 'Error creating form';
          dispatch(setGetQuotation(outputData));
        }
        else {
          outputData['data'] = data.data;
          outputData['message'] = data.message;
          dispatch(setGetQuotation(outputData));
        }
      })
    }
  };
  
  export const setGetQuotation = (data) => ({
      type: types.getQuotation,
      payload: data,
  });


  export const updateQuotation = (numerointerno, sirs, rut, contact, email, phone, obs, price, iva, comission, name, pdf, version) => { 
    const url = `${API_URL}/item/quotation`
    
    return (dispatch) => {
      let outputData = {};
      let params = {
          data: {

            numerointerno: numerointerno,
            sirs: sirs,
            rut: rut,
            contact:contact,
            email: email, 
            phone: phone, 
            obs: obs,
            price:price,
            iva: iva,
            comission: comission,
            name : name,
            pdf: pdf,
            version: version,
          }
        
      };
      Axios.put(url, params).then(({ data }) => {
        if (data.error) {
          outputData['status'] = 'danger';
          outputData['message'] = 'Error creating form';
          dispatch(setUpdateQuotation(outputData));
          }
        else {
  
          outputData['data'] = data.url
          outputData['status'] = data.status;
          outputData['message'] = data.message;
          dispatch(setUpdateQuotation(outputData));
        }
      })
    }
  };
  
  export const setUpdateQuotation = (data) => ({
      type: types.updateQuotation,
      payload: data,
  });


  export const exportQuotation = () => { 
    const url = `${API_URL}/item/quotation/export`
    
    return (dispatch) => {
      let outputData = {};
      Axios.get(url,{ responseType: 'blob' }).then(({ data }) => {
        if (!data ) {
          outputData['status'] = 'danger';
          outputData['message'] = 'Error downloading form';
          dispatch(setExportQuotation(outputData));
        }
        else {
          //download data blob xlsx
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));

          const link = document.createElement('a');
          link.href = url;
          const date = new Date();
          link.setAttribute('download', `Cotizaciones_${date.getDay()}-${date.getMonth()+1}-${date.getFullYear()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);



          outputData['data'] = data.data;
          outputData['status'] = data.status;
          outputData['message'] = data.message;
          dispatch(setExportQuotation(outputData));
        }
      })
    }
  };
  
  export const setExportQuotation = (data) => ({
      type: types.exportQuotation,
      payload: data,
  });