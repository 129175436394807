import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
} from "reactstrap";
import TakeImages from "./TakeImages";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";

import { formPhoto } from "../../redux/actions/forms";
import "react-html5-camera-photo/build/css/index.css";
import Loading from "../Loading/Loading";

function TakePhoto(props) {
  const [loading, setLoading] = useState(true);
  const response = useSelector((state) => state.forms.formPhoto);
  const dispatch = useDispatch();
  const [save, setsave] = useState(false);
  const [images, setImages] = useState([]);
  const [photo, setPhoto] = useState("");
  const [numerointerno, setnumerointerno] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  let history = useHistory();

  useEffect(() => {
    document.title = "BGL - Ingreso de Bienes";
    setLoading(false);
  }, []);
  const onDismiss = () => setShowAlert(false);

  const addImg = (image) => {
    setPhoto(image);
  };

  useEffect(() => {
    if (response) {
      console.log(response);
      if (response.error === false) {
        setImages([...images, response.data]);
      }
      setPhoto("");
      setLoading(false);
      setAlertTitle(response.message);
      setAlertType(response.status);
      setShowAlert(true);
    }
  }, [response]);

  useEffect(() => {
    if (history.location.state === undefined) {
      return;
    } else if (loading === true) {
      let data = history.location.state;
      setnumerointerno(data);
    }
  }, [history.location.state]);

  const saveImage = () => {
    let dict = {
      numerointerno: numerointerno,
      data: photo.data,
    };
    dispatch(formPhoto(dict));
    setLoading(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container style={{ display: photo === "" ? "block" : "none" }}>
        <Row>
          <Col lg='12' md='12' sm='12' >
            <TakeImages addImg={addImg} />
            <ConfirmationModal
              btnClass='col-12 btn btn-danger mt-5'
              containerClass='col-md-12'
              text='terminar'
              title='Terminar toma de fotos'
              message='Estas seguro que quieres terminar la toma de fotos y guardar el Bien?'
              action={() => {
                window.location = "/create-items";
              }}
              argument={""}
            ></ConfirmationModal>
          </Col>
        </Row>
      </Container>
      {photo === "" ? (
        ""
      ) : (
        <Container className='col-md-6'>
          <img src={photo ? photo.data : ""} style={{ width: "100%" }} />
          <Row>
            <Col>
              <Button
                className='col-md-12 btn btn-success'
                onClick={() => {
                  saveImage();
                }}
              >
                {" "}
                Guardar Imagen
              </Button>
            </Col>
            <Col>
              <Button
                className='col-md-12 btn btn-danger'
                onClick={() => {
                  setPhoto("");
                }}
              >
                {" "}
                Volver a tomar
              </Button>
            </Col>
          </Row>
        </Container>
      )}

      <Container style={{ textAlign: "center" }}>
        {images.map((i, index) => {
          return (
            <div>
              <img
                key={index}
                style={{ margin: "0 auto" }}
                src={i}
                alt='Red dot'
              />
            </div>
          );
        })}
      </Container>
    </>
  );
}

export default TakePhoto;
