import React, { useEffect, useState } from 'react';
import { Container, Label, Input, Col } from 'reactstrap';

function FormDocRow(props)
{
  const [check, setcheck] = useState(false);
  const [obs, setobs] = useState('');

  useEffect(() =>
  {
    if (props.value)
    {
      setcheck(props.value.value.checked);
      setobs(props.value.value.obs);
    } else
    {
      setcheck(false);
      setobs("");
    }

  }, [props.value])
  const onChangeCheck = (e) =>
  {
    if (check != false)
    {
      setcheck(false)
      props.setValue({ value: { checked: false, obs: obs }, label: props.name })
      return
    }
    props.setValue({ value: { checked: 'on', obs: obs }, label: props.name })
    setcheck(e.target.value);
  };

  const onChangeObs = (e) =>
  {
    let obsUpper = e.target.value.toUpperCase();
    setobs(obsUpper);
    props.setValue({ value: { checked: check, obs: obsUpper }, label: props.name });
  };

  return (
    <Container style={{ padding: "10px", marginBottom: "20px" }}>
      <Label className="float-left">{props.name}</Label>
      <Col sm="4" className={"float-right"}>
        <Input style={{ textTransform: "uppercase" }} maxLength="55" value={obs} onChange={onChangeObs} type="text" />
      </Col>
      <Col sm="1" className={"float-right"}>
        <Input checked={check} onChange={onChangeCheck} type="checkbox" />
      </Col>

    </Container>
  );
}
export default FormDocRow;