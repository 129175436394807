import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import BodyInspectionRow from "./BodyInspectionRow";

function BodyInspection(props) {
  let defaultValue = { value: { checked: false, quantity: "", obs: "" } };
  const [loading, setLoading] = useState(true);
  const [parabrisas, setParabrisas] = useState(defaultValue);
  const [vidriolat, setVidriolat] = useState(defaultValue);
  const [vidriotra, setVidriotra] = useState(defaultValue);
  const [tapiz, setTapiz] = useState(defaultValue);
  const [tablero, setTablero] = useState(defaultValue);
  const [puertas, setPuertas] = useState(defaultValue);
  const [techo, setTecho] = useState(defaultValue);
  const [parachoques, setParachoques] = useState(defaultValue);
  const [mascara, setMascara] = useState(defaultValue);
  const [capot, setCapot] = useState(defaultValue);
  const [tapamaleta, setTapamaleta] = useState(defaultValue);
  const [tapabarros, setTapabarros] = useState(defaultValue);
  const [otros, setOtros] = useState(defaultValue);
  useEffect(() => {
    document.title = "BGL - Ingreso de Bienes";
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.isPushed) {
      let data = props.value;
      setParabrisas(data.parabrisas);
      setVidriolat(data.vidriolat);
      setVidriotra(data.vidriotra);
      setTapiz(data.tapiz);
      setTablero(data.tablero);
      setPuertas(data.puertas);
      setTecho(data.techo);
      setParachoques(data.parachoques);
      setMascara(data.mascara);
      setCapot(data.capot);
      setTapamaleta(data.tapamaleta);
      setTapabarros(data.tapabarros);
      setOtros(data.otros);
    }
  }, [props.isPushed]);

  useEffect(() => {
    if (props.save === true) {
      props.setValue(getJSON());
    }
  }, [props.save]);

  const getJSON = () => {
    let dict = {
      parabrisas: parabrisas,
      vidriolat: vidriolat,
      vidriotra: vidriotra,
      tapiz: tapiz,
      tablero: tablero,
      puertas: puertas,
      techo: techo,
      parachoques: parachoques,
      mascara: mascara,
      capot: capot,
      tapabarros: tapabarros,
      tapamaleta: tapamaleta,
      otros: otros,
    };
    return dict;
  };

  return (
    <Container
      className='container-border p-3 col-md-12 mt-2'
      style={{ paddingBottom: 100 }}
    >
      <h3>Inspeccion de Carroceria</h3>
      <BodyInspectionRow
        name='Parabrisas'
        value={parabrisas}
        setValue={setParabrisas}
      />
      <BodyInspectionRow
        name='Vidrios Laterales'
        value={vidriolat}
        setValue={setVidriolat}
      />
      <BodyInspectionRow
        name='Vidrios Traseros'
        value={vidriotra}
        setValue={setVidriotra}
      />
      <BodyInspectionRow name='Tapiz' value={tapiz} setValue={setTapiz} />
      <BodyInspectionRow name='Tablero' value={tablero} setValue={setTablero} />
      <BodyInspectionRow name='Puertas' value={puertas} setValue={setPuertas} />
      <BodyInspectionRow name='Techo' value={techo} setValue={setTecho} />
      <BodyInspectionRow
        name='Parachoques'
        value={parachoques}
        setValue={setParachoques}
      />
      <BodyInspectionRow name='Mascara' value={mascara} setValue={setMascara} />
      <BodyInspectionRow name='Capot' value={capot} setValue={setCapot} />
      <BodyInspectionRow
        name='Tapamaleta'
        value={tapamaleta}
        setValue={setTapamaleta}
      />
      <BodyInspectionRow
        name='Tapabarros'
        value={tapabarros}
        setValue={setTapabarros}
      />
      <BodyInspectionRow name='Otros' value={otros} setValue={setOtros} />
    </Container>
  );
}
export default BodyInspection;
