import { types, API_URL } from './types';
import Axios from 'axios';


export const userCreate = (email, password,role, name, phone) => { 
  const url = `${API_URL}/api/users`
  
  return (dispatch) => {
    let outputData = {};
    let params = {
  
        email: email,
        password: password,
        role:role,
        name: name,
        phone:phone
      
    };
    Axios.post(url, params).then(({ data }) => {
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setUserCreate(outputData));
      }
      else {
        console.log(data, "query");
        
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['user'] = data.user
        dispatch(setUserCreate(outputData));
      }
    })
  }
};

export const setUserCreate = (data) => (
  {
    type: types.userCreate,
    payload: data,
});


export const userList = () => { 
  const url = `${API_URL}/api/users`
  
  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then(({ data }) => {
      console.log(data);
      let dataList = [];
      data.users.map((user)=> (dataList.push(user)))
      console.log(dataList);
      
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setUserList(outputData));
      }
      else {
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['data'] = data.users;
        dispatch(setUserList(outputData));
      }
    })
  }
};

export const setUserList = (data) => ({
    type: types.userList,
    payload: data,
});

export const roles = () => { 
  const url = `${API_URL}/api/auth/roles`;
  
  return (dispatch) => {
    let outputData = {};

    Axios.get(url).then(({ data }) => {
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setRoles(outputData));
      }
      else {
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['roles'] = data.roles;

        dispatch(setRoles(outputData));
      }
    })
  }
};

export const setRoles = (data) => ({
    type: types.roles,
    payload: data,
});


export const deleteUser = (data) => { 
  const url = `${API_URL}/api/users`
  let params = {
    data: {
      email: data.email
    }
  }
  
  return (dispatch) => {
    let outputData = {};

    Axios.delete(url, params).then(({ data }) => {
      console.log(data);
      
      
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setDeleteUser(outputData));
      }
      else {
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        dispatch(setDeleteUser(outputData));
      }
    })
  }
};

export const setDeleteUser = (data) => ({
    type: types.deleteUser,
    payload: data,
});

export const userUpdate = (email, password,role, name, phone) => { 
  const url = `${API_URL}/api/users`
  
  return (dispatch) => {
    let outputData = {};
    let params = {
  
        email: email,
        password: password,
        role:role,
        name: name,
        phone:phone
      
    };
    Axios.put(url, params).then(({ data }) => {
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setUserUpdate(outputData));
      }
      else {
        console.log(data, "query");
        
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['user'] = data.user
        dispatch(setUserUpdate(outputData));
      }
    })
  }
};

export const setUserUpdate = (data) => (
  {
    type: types.userUpdate,
    payload: data,
});


export const createClient = (id, email, name,phone ) => { 
  const url = `${API_URL}/clients`
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
          numerointerno: id,
          email: email,
          name: name,
          phone:phone
        }
      
    };
    Axios.post(url, params).then(({ data }) => {
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setCreateClient(outputData));
      }
      else {
        console.log(data, "query");
        
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['data'] = data.data
        dispatch(setCreateClient(outputData));
      }
    })
  }
};

export const setCreateClient = (data) => (
  {
    type: types.createClient,
    payload: data,
});


export const clientList = (id) => { 
  const url = `${API_URL}/clients?numerointerno=${id}`
  
  return (dispatch) => {
    let outputData = {};


    Axios.get(url).then(({ data }) => {
      console.log(data);
      
      
      
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setClientList(outputData));
      }
      else {
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['data'] = data.data;
        dispatch(setClientList(outputData));
      }
    })
  }
};

export const setClientList = (data) => ({
    type: types.clientList,
    payload: data,
});


export const notify = (id, clients) => { 
  const url = `${API_URL}/clients/notify`
  
  return (dispatch) => {
    let outputData = {};
    let params = {numerointerno: id, clients: clients}
    Axios.post(url, params).then(({ data }) => {
      console.log(data);
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Invalid credentials!';
        dispatch(setNotify(outputData));
      }
      else {
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        outputData['data'] = data;
        dispatch(setNotify(outputData));
      }
    })
  }
};

export const setNotify = (data) => ({
    type: types.notify,
    payload: data,
});