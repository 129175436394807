import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Spinner, Button, Label, Input, Alert, Row, Col,
    FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changeFormState, acceptForm, createForms, changeImageStateForm, selectFirstImage } from '../../redux/actions/forms';
import { useLocation } from "react-router-dom";
import ImageUploader from 'react-images-upload';
import Loading from '../../components/Loading/Loading';

function ImageItem() {
    const dispatch = useDispatch();
    const response = useSelector( state => state.forms.create);
    const changeImgState = useSelector(state => state.forms.changeImg);
    const selectFirst = useSelector(state => state.forms.selectFirst);
    const [loading, setLoading] = useState(true);
    const [loadingC, setLoadingC] = useState(true);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    const [uploadImages, setUploadImages] = useState();
    let history = useHistory();

    useEffect(() => {
        document.title = 'BGL - Bienes';
        window.scrollTo(0, 0);
  
      }, []);
  
      useEffect(()=> {
        if (history.location.state === undefined){
          return
        } else if( loading === true && loadingC) {
          let data = history.location.state
          setData(data);
          console.log(data);
          setImages(data.img);
          setLoading(false);
          setLoadingC(false);
        }
      },[history.location.state])

      useEffect(() => {
        if (response && loadingC == false) {
            console.log(response);
            if (response.data){
                console.log(response.data);
                setData(response.data)
                let state = response.data
                setImages(state.img);
                history.replace({ ...history.location, state});
                setLoading(false);
                setAlertTitle(response.message);
                setAlertType("success");
                setShowAlert(true)
            }
        }
      }, [response]);


      const onDismiss = () => setShowAlert(false);
      useEffect(() => {
        if (selectFirst)  {
            console.log(selectFirst);
            if (selectFirst.data){
                setData(selectFirst.data.data)
                let state = selectFirst.data.data
                setImages(state.img);
                history.replace({ ...history.location, state});
                setLoading(false);
            } 
        }
      }, [selectFirst]);

      useEffect(() => {
        if (changeImgState && loadingC == false) {
            console.log(changeImgState);
            if (changeImgState.data){
                setData(changeImgState.data.form)
                let state = changeImgState.data.form
                setImages(state.img);
                history.replace({ ...history.location, state});
                setLoading(false);
            } 
        }
      }, [changeImgState]);

      const setFirstImage = (item) => {
        let auxF = data.img.filter((img)=>img.first === true)[0];
        let auxD = data.img.filter((img) => !img.first);
        let auxI = data.img.filter((img)=>img.data === item)[0];
        let auxImg = auxD.filter((img)=>img.data !== item);
        auxI.first = true;
        auxImg = [...auxImg, auxI]
        auxImg = auxImg.reverse()
        if (auxF) {
          delete auxF.first
          auxImg = [...auxImg, auxF]
        }
        dispatch(selectFirstImage(data.head.numerointerno.value,auxImg))


      }



    
    const UploadImagesCallback = (base, index, total) => {
        let auxData = data;
        let auxImg = data.img
        console.log(auxImg)
        let img = {
            web: false,
            data: base
        }
        auxImg = [...auxImg, img]
        auxData.img = auxImg;
        if (index + 1 === total){
            dispatch(createForms(auxData));
        }
    }

    const UploadImages = () => {
        setLoading(true);
        let auxData = data;
        let auxImg = data.img
        let uploadImgFormat = []
        if (uploadImages !== undefined){
          uploadImages.map((item, index) => {
            getBase64(item, UploadImagesCallback, index, uploadImages.length)
        })
        }
        
        auxData.img = auxImg;
        setData(auxData);
    }


    const onDrop = (picture) => {
        console.log(picture);
        setUploadImages(picture);
    }
    ;

    const changeImageState = ( item) => {
        let auxD = data
        console.log(auxD);
        console.log(item);
        let auxI = auxD.img.filter((img)=>img.data === item)[0];
        console.log(auxD, auxI);
        dispatch(changeImageStateForm(auxD.head.numerointerno.value,auxI.data,!auxI.web))
        

    }

    const getBase64 = (file, callback, index, total) => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
          // Convert the file to base64 text
          reader.readAsDataURL(file);
          reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
            callback(baseURL, index, total)
          };
        });
      };
    
    return loading?  (
    <Loading />
    ) :(
      <>
      <Container>
          <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
            {alertTitle} - {alertMessage}
          </Alert>  
        </Container>
        <Container>
            <h2>Imagenes</h2>
              <Row>
                  
                  <ImageUploader
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.png', '.jpeg']}
                    maxFileSize={5242880}
                    withPreview={true}
                  />
                  
                  <Button 
                  onClick={() => {UploadImages(images)}} disabled={uploadImages ? false : true}>
                                               guardar Imagenes
                  
                </Button>
                </Row>
              <Row>
              <Col lg='12'>
              <Container fluid={true}>
                      <Row >
                          {images.map((item, indexCol)=> {
                              return (
                              <Col xs="6" sm="4" md="3" key={indexCol} className="m-6">
                                <a href={item.data}   target="_blank">
                                  <img src={item.data}/>
                                </a>
                              <div className='custom-control custom-switch'>
                                  <input
                                  type='checkbox'
                                  key={indexCol}
                                  className='custom-control-input'
                                  id={item.data}
                                  checked={item.web}
                                  onChange={() => changeImageState(item.data)}
                                  />
                                  <a className='float-right m-3'
                                    onClick={() => {
                                      setFirstImage(item.data);
                                    }} >
                                      {item.first ? <i className='fa fa-star'  style={{'color':'black'}}></i>
                                       :  <i className='far fa-star'  style={{'color':'black'}}></i>}
                                  </a>
                                  <label className='custom-control-label' htmlFor={item.data}>
                                  Mostrar en Pagina Web
                                  </label>

                              </div>
                                
                              </Col>
                          )

                          })}
                      </Row>
                  </Container>
                  </Col>         
              </Row>
        </Container>
        </>
    )
};

export default ImageItem;
            
