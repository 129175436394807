import { types } from '../actions/types';

export const OfferReducer = (state = { data: null ,create: null, get: null , update: null, export: null}, action) => {
    switch (action.type) {
        case types.createOffer:
            return {
              ...state,
              create: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,
              } 
            };
        case types.updateOffer:
                return {
                  ...state,
                  update: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                  } 
                };
        case types.getOffer:
            return {
                ...state,
                get: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,  
                } 
            };
        case types.exportOffer:
            return {
                ...state,
                export: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,
                }
            }  
        
        default:
            return state;
    }
}