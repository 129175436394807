import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Collapse, Table} from 'reactstrap';




function ItemInfo(props) {
  const [data, setData] = useState(props.data);
  const names= {
    head: "Especificaciones",
    bodyinspection: "Inspeccion",
    mecanicState: "Estado Mecanico",
    accesoriesInventory: "Accesorios e Inventario"
  };
  const [isOpenSpecification, setIsOpenSpecificacion] = useState(
    false
  );
  const toggleSpecificacion = () => setIsOpenSpecificacion(!isOpenSpecification);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      {Object.keys(data).map((item, index) => {
        return item in names ? (
          <Container key={index}>
            <h3 onClick={toggleSpecificacion}>{names[item]}</h3>
            <hr
              style={{
                backgroundColor: "#000000",
                height: 0.5,
                color: "#000000",
                borderColor: "#000000"
              }}
            />
            <Collapse isOpen={isOpenSpecification}>
              <Table>
                <tbody>
                  {Object.keys(data[item]).map((row, index) => {
                    return data[item][row].label ? (
                      typeof data[item][row].value !== "string" ? (
                        <tr key={index}>
                          <td>{data[item][row].label}</td>
                          <td>
                            {data[item][row].value.checked === "on"
                              ? "SI"
                              : "No"}
                          </td>
                          <td>{data[item][row].value.quantity}</td>
                          <td> {data[item][row].value.obs}</td>
                        </tr>
                      ) : (
                        <tr key={index}>
                          <td>{data[item][row].label}</td>
                          <td> {data[item][row].value}</td>
                        </tr>
                      )
                    ) : (
                      <tr key={index}></tr>
                    );
                  })}
                </tbody>
              </Table>
            </Collapse>
          </Container>
        ) : (
          <div key={index}></div>
        );
      })}
    </>
  );
}
export default ItemInfo;