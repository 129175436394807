import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  Input,
  Alert,
  Row,
  Col,
  Table,
} from "reactstrap";
import {
  getProvider,
  createProvider,
  deleteProvider,
} from "../../redux/actions/provider";
import Loading from "../../components/Loading/Loading";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";

function Provider() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.provider.createProvider);
  const providers = useSelector((state) => state.provider.providers);
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "BGL - Mandantes";
    dispatch(getProvider());
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "success") {
        setName("");
        setNumber("");
        dispatch(getProvider());
      }
      setShowAlert(true);
      setAlertTitle(response.status);
      setAlertType(response.status);
      setAlertMessage(response.message);
    }
  }, [response]);

  useEffect(() => {
    if (providers) {
      console.log(providers);
      if (providers.message === "Providers") {
        setData(providers.data);
        console.log(providers);
        setLoading(false);
      } else if (providers.message === "Provider deleted") {
        dispatch(getProvider());
      }
    }
  }, [providers]);

  const onDismiss = () => setShowAlert(false);

  const deleteProviders = (num) => {
    setLoading(true);
    dispatch(deleteProvider(num));
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    //setLoading(true);

    if (name == "" || number == "") {
      setShowAlert(true);
      setAlertTitle("Error");
      setAlertType("danger");
      setAlertMessage("Debes ingresar un código y nombre para el Mandante");
      return;
    }
    dispatch(createProvider(name, number));
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>

      <Container
        className='container-border p-3'
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <h2>Crear Mandante</h2>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col>
              <Input
                className='col-md mt-2'
                placeholder='Codigo'
                type='text'
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </Col>
            <Col>
              <Input
                className='col-md mt-2'
                placeholder='Mandante'
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col>
              <Button color='success' className='col-md'>
                Crear
                <i className='fa fa-plus pl-3'></i>
              </Button>
            </Col>
          </Row>
        </Form>
        <h2>Lista Mandante</h2>
         <Table hover >
          <thead>
            <tr>
              <th>Numero</th>
              <th>Nombre</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>
                    <ConfirmationModal
                      btnClass='btn btn-danger'
                      iconClass='fa fa-trash'
                      title='Eliminar Mandante'
                      message='Estas seguro que quieres eliminar a este mandante?'
                      action={deleteProviders}
                      argument={row.id}
                    ></ConfirmationModal>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
export default Provider;
