import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  Input,
  Alert,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Collapse,
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import {

  getCategories,
  createCategory,
  deleteProvider,
} from "../../redux/actions/provider";
import Loading from "../../components/Loading/Loading";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import CategoryTypeModal from "../../components/Modal/CategoryTypeModal";

function Category() {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.provider.createCategory);
  const responseType = useSelector((state) => state.provider.createCategoryType);
  const categories = useSelector((state) => state.provider.categories);

  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");

  const [category, setCategory] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "BGL - Mandantes";
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "success") {
        setName("");
        setNumber("");
        dispatch(getCategories());
      }
      setShowAlert(true);
      setAlertTitle(response.status);
      setAlertType(response.status);
      setAlertMessage(response.message);
    }
  }, [response]);

  useEffect(() => {
    if (responseType) {
      if (responseType.status === "success") {

        dispatch(getCategories());
      }
      setShowAlert(true);
      setAlertTitle(responseType.status);
      setAlertType(responseType.status);
      setAlertMessage(responseType.message);
    }
  }, [responseType]);

  useEffect(() => {
    if (categories) {
      if (categories.message === "Categories") {
        console.log(categories.data.data);
        setData(categories.data.data);
        setLoading(false);
      } else if (categories.message === "Provider deleted") {
        dispatch(getCategories());
      }
    }
  }, [categories]);



  const onDismiss = () => setShowAlert(false);

  const deleteProviders = (num) => {
    setLoading(true);
    dispatch(deleteProvider(num));
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    //setLoading(true);

    if (name == "") {
      setShowAlert(true);
      setAlertTitle("Error");
      setAlertType("danger");
      setAlertMessage("Debes ingresar un código y nombre para el Mandante");
      return;
    }
    dispatch(createCategory(number, name));
  };

  const toggle = (nameC) =>  {
      setCategory(nameC);

  }
  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>

      <Container
        className='container-border p-3'
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <h2>Crear Categoria</h2>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col>
              <Input
                className='col-md mt-2'
                placeholder='Categoria'
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col>
            <Input
                className='col-md mt-2'
                placeholder='Numero'
                type='text'
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />

            </Col>
            <Col>
              <Button color='success' className='col-md'>
                Crear Categoria
                <i className='fa fa-plus pl-3'></i>
              </Button>
            </Col>
          </Row>
        </Form>
        <h2>Categorias</h2>
        
            {data.map((row, index) => {
              return (
                  
                <Card  style={{ marginBottom: '1rem'}} key={row.category_name}>
                <CardHeader style={{backgroundColor:'secondary'}} onClick={() =>toggle(row.category_name)}>
                    <div>
                
                <CategoryTypeModal modalCategory={row}
                >
                
              </CategoryTypeModal>
              <h3 className="pt-2">{row.category_name} - {row.category_value}</h3>
              </div>
                </CardHeader>
                <Collapse isOpen={row.category_name === category}>
                  <CardBody >
                    <h5> Lista de Tipos </h5>
                  <ListGroup>
      
                  {row.types.map(type =>{
                      return(<ListGroupItem style={{textAlign:'center'}} >{type.name}</ListGroupItem>)
                  })}
                  </ListGroup>
                  </CardBody>
                </Collapse>
              </Card>
                
                 
              );
            })}

      </Container>
            </>
  );
}
export default Category;
