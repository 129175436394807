import { types } from '../actions/types';

export const authReducer = (state = { token: null, status: null, message: null, signIn: null }, action) => {
    switch (action.type) {
        case types.signIn:
            return {
              ...state,
              signIn: {
                token: action.payload.token,
                status: action.payload.status,
                message: action.payload.message
              },
              token: action.payload.token,
            };
        case types.signOut:
            return {
              ...state,
              token: null,
              status: action.payload.status,
              message: action.payload.message
            };
        default:
            return state;
    }
}