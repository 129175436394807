import { combineReducers } from 'redux';
import { languageReducer, authReducer , quotationReducer, userReducer, formReducer, providerReducer, OfferReducer } from './index';



export const rootReducer = combineReducers({
  lang: languageReducer,
  auth: authReducer,
  user: userReducer,
  forms: formReducer,
  provider: providerReducer,
  quotation: quotationReducer,
  offer: OfferReducer,
});