export function getLastKey(obj) {
    if (!obj || Object.keys(obj).length === 0) {
        return null;
    }

    const maxDateKey = Object.keys(obj).reduce((max, key) => {
        const date = new Date(key);
        return date > new Date(max) ? key : max;
    }, Object.keys(obj)[0]);

    return maxDateKey;
}