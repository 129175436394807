import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";
import { signIn } from "../../redux/actions/auth";
import Loading from "../../components/Loading/Loading";

function SignIn() {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const response = useSelector((state) => state.auth.signIn);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  let { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    document.title = "BGL - Sign In";
    setLoading(false);
  }, []);

  useEffect(() => {
    if (response) {
      console.log(response);
      if (response.token !== null) {
        history.push({ pathname: "/" });
        setAlertType(response.status);
        setAlertMessage(response.message);
        setShowAlert(true);
        setLoading(false);
      }
    }
  }, [response]);

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(signIn(email, password));
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container className='col-md-12' style={{ margin: "30px" }}>
        <Row>
          <Col lg='4'></Col>
          <Col className='container-border p-3' sm='12' lg='4'>
            <Form onSubmit={(e) => onFormSubmit(e)}>
              <FormGroup>
                <Label style={{ fontSize: 18 }} for='email'>
                  Email
                </Label>
                <Input
                  type='text'
                  placeholder='Email...'
                  disabled={loading}
                  style={{ textTransform: "none" }}
                  onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontSize: 18 }} for='password'>
                  Password
                </Label>
                <Input
                  type='password'
                  placeholder='· · · · · ·'
                  disabled={loading}
                  style={{ textTransform: "none" }}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <Button disabled={loading}>Entrar</Button>
            </Form>
          </Col>
          <Col lg='4'></Col>
        </Row>
      </Container>
    </>
  );
}
export default SignIn;
