import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";
import ItemType from "./ItemType";
import MecanicStateRow from "./MecanicStateRow";

function MecanicState(props) {
  const [loading, setLoading] = useState(true);
  const defaultValue = { value: { checked: false, obs: "" } };
  const [motor, setmotor] = useState(defaultValue);
  const [transmision, settransmision] = useState(defaultValue);
  const [cajaCambios, setcajaCambios] = useState(defaultValue);
  const [otros, setotros] = useState(defaultValue);
  const [funciona, setfunciona] = useState(defaultValue);
  const [combustible, setcombustible] = useState(defaultValue);
  const [brazos, setbrazos] = useState(defaultValue);
  const [carroceria, setcarroceria] = useState(defaultValue);
  const [rodados, setrodados] = useState(defaultValue);
  const [sisElectrico, setsisElectrico] = useState(defaultValue);
  const [sisHidraulico, setsisHidraulico] = useState(defaultValue);
  const [neumaticos, setneumaticos] = useState(defaultValue);
  useEffect(() => {
    document.title = "BGL - Ingreso de Bienes";
  }, []);

  useEffect(() => {
    if (props.isPushed) {
      if (props.type === "machinery") {
        let data = props.value;
        setmotor(data.motor);
        settransmision(data.transmision);
        setcajaCambios(data.cajaCambios);
        setotros(data.otros);
        setfunciona(data.funciona);
        setcombustible(data.combustible);
        setbrazos(data.brazos);
        setcarroceria(data.carroceria);
        setsisHidraulico(data.sisHidraulico);
        setsisElectrico(data.sisElectrico);
        setneumaticos(data.neumaticos);
      } else {
        let data = props.value;
        setmotor(data.motor);
        settransmision(data.transmision);
        setcajaCambios(data.cajaCambios);
        setotros(data.otros);
        setfunciona(data.funciona);
        setcombustible(data.combustible);
        setsisHidraulico(data.sisHidraulico);
        setsisElectrico(data.sisElectrico);
      }
    }
  }, [props.isPushed]);

  useEffect(() => {
    if (props.save === true) {
      props.setValue(getJSON());
    }
  }, [props.save]);

  const getJSON = () => {
    if (props.type === "machinery") {
      let dict = {
        motor: motor,
        transmision: transmision,
        cajaCambios: cajaCambios,
        otros: otros,
        funciona: funciona,
        combustible: combustible,
        brazos: brazos,
        carroceria: carroceria,
        sisElectrico: sisElectrico,
        sisHidraulico: sisHidraulico,
        neumaticos: neumaticos,
      };
      return dict;
    } else {
      let dict = {
        motor: motor,
        transmision: transmision,
        cajaCambios: cajaCambios,
        otros: otros,
        funciona: funciona,
        combustible: combustible,
        sisElectrico: sisElectrico,
        sisHidraulico: sisHidraulico,
      };
      return dict;
    }
  };

  return (
    <Container
      className='col-md-12 container-border p-3 mt-2'
      style={{ paddingBottom: 100 }}
    >
      <h3>Estado Mecánico</h3>
      {props.type === "machinery" ? (
        <Container>
          <MecanicStateRow value={motor} setValue={setmotor} name='Motor' />
          <MecanicStateRow
            value={transmision}
            setValue={settransmision}
            name='Transmisión/Diferencial'
          />
          <MecanicStateRow
            value={cajaCambios}
            setValue={setcajaCambios}
            name='Caja de Cambios'
          />
          <MecanicStateRow value={otros} setValue={setotros} name='Otros' />
          <MecanicStateRow
            value={brazos}
            setValue={setbrazos}
            name='Brazos/Baldes/Tambor Rotato'
          />
          <MecanicStateRow
            value={carroceria}
            setValue={setcarroceria}
            name='Carrocería/Pintura'
          />
          <MecanicStateRow
            value={funciona}
            setValue={setfunciona}
            name='Funciona'
          />
          <MecanicStateRow
            value={combustible}
            setValue={setcombustible}
            name='Tipo de combustible'
          />
          <MecanicStateRow
            value={sisElectrico}
            setValue={setsisElectrico}
            name='Sistema Eléctrico'
          />
          <MecanicStateRow
            value={sisHidraulico}
            setValue={setsisHidraulico}
            name='Sistema Hidráulico'
          />
          <MecanicStateRow
            value={neumaticos}
            setValue={setneumaticos}
            name='Rodados/Neumaticos'
          />
        </Container>
      ) : (
        <Container>
          <MecanicStateRow value={motor} setValue={setmotor} name='Motor' />
          <MecanicStateRow
            value={transmision}
            setValue={settransmision}
            name='Transmisión/Diferencial'
          />
          <MecanicStateRow
            value={rodados}
            setValue={setrodados}
            name='Rodados/Neumáticos'
          />
          <MecanicStateRow
            value={sisElectrico}
            setValue={setsisElectrico}
            name='Sistema Eléctrico'
          />
          <MecanicStateRow
            value={sisHidraulico}
            setValue={setsisHidraulico}
            name='Sistema Hidráulico'
          />
          <MecanicStateRow value={otros} setValue={setotros} name='Otros' />
          <MecanicStateRow
            value={funciona}
            setValue={setfunciona}
            name='Funciona'
          />
          <MecanicStateRow
            value={combustible}
            setValue={setcombustible}
            name='Tipo de combustible'
          />
        </Container>
      )}
    </Container>
  );
}
export default MecanicState;
