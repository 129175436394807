import { types } from '../actions/types';

export const formReducer = (state = { data: null, valid: null, forms: null,  change: null, create: null, changeImg: null, getItem: null,
     formFile: null , prov: null, web: null, formPhoto: null, feature: null, getItemStorage: null, selectFirst: null}, action) => {
    switch (action.type) {
        case types.createdForm:
            return {
              ...state,
              create: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

              }
            };
        case types.feature:
            return {
              ...state,
              feature: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

              }
            };
        case types.selectFirst:
            return {
                ...state,
                selectFirst: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

                }
            };
        case types.getItemStorage:
            return {
                ...state,
                getItemStorage: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,

                }
            };
        case types.acceptForm:
            return {
                ...state,
                prov: {
                    prov: action.payload.prov,
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,

                }
               
            };
        case types.webPageForm:
            return {
                ...state,
                web: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,

                }
                
            };
        case types.formFile:
            return {
                ...state,
                formFile: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                }
                
            };    
        case types.changeImageStateForm:
            return {
                ...state,
                changeImg: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                }
                
            };    
        case types.setForms:
            return {
                ...state,
                forms: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                }
            };
        case types.getItem:
            return {
                ...state,
                getItem:{
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                } 
         
            };
        case types.formPhoto:
                return {
                    ...state,
                    formPhoto:{
                        status: action.payload.status,
                        message: action.payload.message,
                        data: action.payload.data,
                    } 
             
                };
        case types.validItem:
            return {
                ...state,
                valid: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                }
            };
        case types.changeFormState:
            return {
                ...state,
                change: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,
                }
                
            };
        
        default:
            return state;
    }
}