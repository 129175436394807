import React, { useState, useEffect } from 'react';
import ItemType from '../../components/Item/ItemType';
import ItemFormVeh from '../../components/Item/ItemFormVeh';
import ItemFormMany from '../../components/Item/ItemFormMany';
import ItemFormMachinery from '../../components/Item/ItemFormMachinery';
import { Container, Spinner, Form, Button, FormGroup, Label, Input, Alert, Row, Col} from 'reactstrap';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
const ItemForm = (props) => {

  const [formType, setformType] = useState(0);
  const [data, setData] = useState({});
  const [isPushed, setisPushed] = useState(false);
  const location = useLocation();
  let history = useHistory();
  useEffect(() => {
    if (location.state === undefined){
      return
    }
    let data = location.state.data
    if(data.type == 'car'){
        setformType(1)
    }
    else if(data.type == 'truck'){
        setformType(2)
    }
    else if(data.type == 'machinery'){
        setformType(3)
    }
    else if(data.type == 'many'){
        setformType(4)
    }
    setData(data);
    setisPushed(true)
  }, [location]);
  return (
    <Container  style={{paddingBottom: 100}}> 
       
        <h2 className="d-flex justify-content-center mb-2">{data.head ? "NUMERO INTERNO: " + data.head.numerointerno.value : ""}</h2>
        {data.head ?  <Button
        color='info'
        className='float-right'
        onClick={() => {
          history.goBack();
        }}
      >
        <i className='fa fa-chevron-left' aria-hidden='true'></i>
      </Button>: ""}
        
       
        {
            formType == 0 ? <ItemType  setValue={setformType} /> : <div></div>
        }
        {   
            formType == 1 ? <ItemFormVeh  type={'car'} isPushed={isPushed} data={data} /> : <div></div>
        }
        {
            formType == 2 ? <ItemFormVeh type={'truck'} isPushed={isPushed} data={data} /> : <div></div>
        }
        {
            formType == 3 ? <ItemFormMachinery type={'machinery'} isPushed={isPushed} data={data} /> : <div></div>
        }
        {
            formType == 4 ? <ItemFormMany isPushed={isPushed} data={data} /> : <div></div>
        }
    </Container>
  );
}

export default ItemForm;