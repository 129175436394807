import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { userCreate, roles, userUpdate } from "../../redux/actions/user";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function UserCreate() {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.lang.lang);
  const response = useSelector((state) => state.user);
  const getRoles = useSelector((state) => state.user.roles);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("vendedor");
  const [phone, setPhone] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [update, setUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const location = useLocation();

  const toggle = () => setOpen(!dropdownOpen);

  useEffect(() => {
    console.log(response);

    if (response.status === "success") {
      setAlertTitle(response.message);
      setAlertMessage(response.message);
      setLoading(false);
      setShowAlert(true);
      setEmail("");
      setName("");
      setPhone("");
      setRole("");
    }
  }, [response]);

  useEffect(() => {
    console.log(response, "tiutle");
    document.title = "BGL - Crear Usuario";
    if (!getRoles) {
      dispatch(roles());
    }
    setLoading(false);
  }, [getRoles]);

  useEffect(() => {
    if (location.state === undefined) {
      return;
    } else if (update === false) {
      let data = location.state.data;
      console.log(data);
      setEmail(data.email);
      setName(data.name);
      setPhone(data.phone);
      setRole(data.role_name);
      setUpdate(true);
    }
    setLoading(false);
  });

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (update) {
      dispatch(userUpdate(email, password, role, name, phone));
    } else {
      dispatch(userCreate(email, password, role, name, phone));
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container className='container-border p-3 mt-3'>
        <Row>
          <Col lg='4'></Col>
          <Col sm='12' lg='12'>
            <Form onSubmit={(e) => onFormSubmit(e)}>
              <FormGroup>
                <Label style={{ fontSize: "24px" }} for='role'>
                  Tipo de Usuario
                </Label>

                <ButtonDropdown
                  className='ml-3'
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  disabled={loading}
                >
                  <DropdownToggle caret>{role}</DropdownToggle>
                  <DropdownMenu className='container-border'>
                    {getRoles &&
                      getRoles.map((rol, index) =>
                        rol.role_name !== role ? (
                          <DropdownItem
                            key={index}
                            onClick={(e) => setRole(e.target.value)}
                            value={rol.role_name}
                          >
                            {rol.role_name}
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            key={index}
                            onClick={(e) => setRole(e.target.value)}
                            selected
                            value={rol.role_name}
                          >
                            {rol.role_name}
                          </DropdownItem>
                        )
                      )}
                  </DropdownMenu>
                </ButtonDropdown>
              </FormGroup>
              <FormGroup>
                <Label for='email'>Email</Label>
                <Input
                  type='text'
                  placeholder='Email...'
                  disabled={update || loading}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}

                />
              </FormGroup>
              <FormGroup>
                <Label for='password'>Password</Label>
                <Input
                  type='password'
                  placeholder='· · · · · ·'
                  disabled={loading}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </FormGroup>
              <FormGroup>
                <Label for='name'>Nombre</Label>
                <Input
                  type='text'
                  placeholder='Nombre...'
                  disabled={loading}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </FormGroup>
              <FormGroup>
                <Label for='phone'>Telefono</Label>
                <Input
                  type='text'
                  placeholder='Telefono...'
                  disabled={loading}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </FormGroup>
              <Button color='success' disabled={loading}>
                Guardar
              </Button>
            </Form>
          </Col>
          <Col lg='4'></Col>
        </Row>
      </Container>
    </>
  );
}
export default UserCreate;
