import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import
  {
    Container,
    Spinner,
    Form,
    Button,
    FormGroup,
    Label,
    Input,
    Alert,
    Row,
    Col,
  } from "reactstrap";
import ItemType from "./ItemType";
import FormHeader from "./FormHeader";
import BodyInspection from "./BodyInspection";
import AccesoriesInventory from "./AccesoriesInventory";
import MecanicState from "./MecanicState";
import AddComments from "./AddComments";
import TakeImages from "./TakeImages";
import { createForms, acceptForm } from "../../redux/actions/forms";
import { useLocation } from "react-router-dom";
import ConfirmSaveModal from "./ConfirmSaveModal";
import { useHistory } from "react-router-dom";

import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { triggerBase64Download } from "react-base64-downloader";
import TakePhoto from "./TakePhoto";

function ItemFormMachinery(props)
{
  const [loading, setLoading] = useState(true);
  const responseC = useSelector((state) => state.forms.create);
  const responseP = useSelector((state) => state.forms.prov);
  const dispatch = useDispatch();
  const [save, setsave] = useState(false);
  const [img, setImage] = useState([]);
  const [mecanicState, setmecanicState] = useState({});
  const [header, setheader] = useState({});
  const [bodyinspection, setbodyinspection] = useState({});
  const [accesoriesInventory, setaccesoriesInventory] = useState({});
  const [numeroInternoForUpdate, setNumeroInternoForUpdate] = useState("");

  const [comments, setComments] = useState("");
  const [suresave, setsuresave] = useState(false);
  const [isPushed, setIsPushed] = useState(false);
  const location = useLocation();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);


  let history = useHistory();

  useEffect(() =>
  {
    document.title = "BGL - Ingreso de Bienes";
    setLoading(false);
  }, []);
  const onDismiss = () => setShowAlert(false);
  useEffect(() =>
  {
    setLoading(false);
    if (responseP === null)
    {
    } else if (responseP.prov === "accept")
    {
      setAlertTitle("Aprobar");
      setAlertMessage(responseP.message);
      setAlertType(responseP.status);
      setShowAlert(true);
      return;
    }
    if (responseC === null)
    {
    } else if (
      responseC.status === "success" &&
      responseC.message !== "Forms"
    )
    {
      setAlertTitle("Creado");
      setAlertMessage(responseC.message + " - " + responseC.data.id);
      setAlertType(responseC.status);
      setShowAlert(true);

      history.push({
        pathname: "/item-photo",
        state: responseC.data.head.numerointerno.value,
      });
    }
  }, [responseC, responseP]);
  useEffect(() =>
  {
    if (props.isPushed === false)
    {
      return;
    }
    let data = props.data;
    setNumeroInternoForUpdate(data.head.numerointerno);
    setImage(data.img);
    setmecanicState(data.mecanicState);
    setheader(data.head);
    setaccesoriesInventory(data.accesoriesInventory);
    setbodyinspection(data.bodyinspection);
    setComments(data.comments);
    setIsPushed(props.isPushed);
  }, [props.isPushed]);

  const deleteImage = (image) =>
  {
    let imgs = img.filter((item) => item !== image);
    setImage(imgs);
  };

  useEffect(() =>
  {
    if (suresave === true)
    {

      if (header.mandante.value !== "" && header.tipo.value !== "")
      {

        let dict = {}
        if (props.isPushed)
        {
          let form = props.data;
          form.type = props.type
          form.head = header
          form.mecanicState = mecanicState
          form.bodyinspection = bodyinspection
          form.accesoriesInventory = accesoriesInventory
          form.comments = comments
          form.img = img
          dict = form
        }
        else
        {
          dict = {
            type: props.type,
            head: header,
            mecanicState: mecanicState,
            bodyinspection: bodyinspection,
            accesoriesInventory: accesoriesInventory,
            comments: comments,
            img: img,
            approved_by: [],
          };
        }
        dispatch(createForms(dict));
      } else
      {

        setAlertTitle("Faltan Datos");
        setAlertMessage("Debe ingresar ");
        setAlertType("danger");
        setsave(false);
        setsuresave(false);
        setShowAlert(true);

      }

    }
  }, [suresave]);
  const acceptItem = () =>
  {
    console.log(numeroInternoForUpdate);

    dispatch(acceptForm(numeroInternoForUpdate));
  };

  return (
    <div id={"capture"}>
      <Container>
        <Alert color={alertType} isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>

      <Container style={{ height: "70px" }}>
        {/*<ItemType setForm={setForm}></ItemType>*/}
        <ConfirmSaveModal
          buttonLabel='Guardar'
          save={setsave}
          suresave={setsuresave}
        />
        {isPushed ? (
          <Button onClick={() => acceptItem()} className='btn btn-primary float-right'>Aprobar</Button>
        ) : (
          ""
        )}
      </Container>
      <Container className='container-border pb-3 pt-3'>
        <FormHeader
          isPushed={isPushed}
          save={save}
          setValue={setheader}
          many={false}
          value={header}
        ></FormHeader>
      </Container>
      <Container>
        <AccesoriesInventory
          isPushed={isPushed}
          save={save}
          value={accesoriesInventory}
          setValue={setaccesoriesInventory}
        />
      </Container>
      <Container>
        <MecanicState
          type={props.type}
          isPushed={isPushed}
          save={save}
          value={mecanicState}
          setValue={setmecanicState}
        />
      </Container>
      <Container>
        <AddComments
          isPushed={isPushed}
          value={comments}
          setValue={setComments}
          save={save}
        />
      </Container>
      <Container style={{ textAlign: "center" }}>
        {img.map((i, index) =>
        {
          return (
            <div key={index}  className="mt-2">
              <Button className='btn-sm btn-danger float-right position-absolute' onClick={() => deleteImage(i)}>
                <i className={"fa fa-times"}></i>
              </Button>
              <img
                key={index}
                style={{ margin: "0 auto" }}
                src={i.data}
                alt='Red dot'
              />
            </div>
          );
        })}
      </Container>
    </div>
  );
}
export default ItemFormMachinery;
