import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Label, Input } from "reactstrap";

function AddComments(props) {
  const [loading, setLoading] = useState(true);
  const [comment, setcomment] = useState(props.value);
  useEffect(() => {
    setcomment(props.value);
  }, [props.value]);
  useEffect(() => {
    if (props.save === true) {
      props.setValue(comment);
    }
  }, [props.save]);

  return (
    <Container
      className='container-border p-3 mt-2'
      style={{ padding: "10px", marginBottom: "100px" }}
    >
      <h3>Observaciones Generales</h3>
      <Input
        type='textarea'
        style={{ textTransform: "uppercase" }}
        onChange={(e) => setcomment(e.target.value)}
        value={comment}
        style={{ height: 500 }}
      />
    </Container>
  );
}
export default AddComments;
