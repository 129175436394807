import React, { useEffect, useState, useDebugValue } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  FormGroup,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loading from "../Loading/Loading";

function ConfirmationModal(props) {
  const dispatch = useDispatch();
  const event = props.function;
  const btnClass = props.btnClass;
  const iconClass = props.iconClass;
  const action = props.action;
  const argument = props.argument;
  const title = props.title;
  const message = props.message;
  const text = props.text;
  const containerClass = props.containerClass;
  const [modal, setModal] = useState(false);
  let history = useHistory();

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div
      className={containerClass ? containerClass : ""}
      style={{ display: "inline-block" }}
    >
      <Button className={btnClass} onClick={toggle}>
        {text}
        {iconClass ? <i className={iconClass}></i> : ""}
      </Button>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='desc'>{message}</Label>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color='success' onClick={() => action(argument)}>
            Guardar
          </Button>
          <Button color='danger' onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmationModal;
