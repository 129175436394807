import { types, API_URL } from './types';
import Axios from 'axios';


export const createOffer = (numerointerno, name, rut, email, phone, obs, price, iva, comission, wayToPay, version) => { 
  const url = `${API_URL}/item/offer`
  
  
  return (dispatch) => {
    let outputData = {};
    let params = {
        data: {
            
          numerointerno: numerointerno,
          name: name,
          rut: rut,
          email: email, 
          phone: phone, 
          obs: obs,
          price:price,
          iva: iva,
          comission: comission,
          wayToPay: wayToPay,
          version: version,
        }
      
    };
    console.log(params);
    
    Axios.post(url, params).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setCreateOffer(outputData));
      }
      else {

        console.log(data);
        
        outputData['data'] = data.url;
        outputData['status'] = data.status;
        outputData['message'] = data.message;
        dispatch(setCreateOffer(outputData));
      }
    })
  }
};

export const setCreateOffer = (data) => ({
    type: types.createOffer,
    payload: data,
});

export const getOffer = (filters) => { 
  let url = `${API_URL}/item/offer?`
  let filterString = '';
  if (filters) {
    Object.keys(filters).map((filter) => {
      console.log(filter);
      filterString += `&${filter}=${filters[filter]}`
    })

  }
  console.log(filterString);
  
  url += filterString
    
    
    return (dispatch) => {
      let outputData = {};
    
      Axios.get(url).then(({ data }) => {
        if (data.error) {
          outputData['status'] = 'danger';
          outputData['message'] = 'Error creating form';
          dispatch(setGetOffer(outputData));
        }
        else {
          outputData['data'] = data.data;
          outputData['message'] = data.message;
          dispatch(setGetOffer(outputData));
        }
      })
    }
  };
  
  export const setGetOffer = (data) => ({
      type: types.getOffer,
      payload: data,
  });


  export const updateOffer = (id, numerointerno, name, rut, email, phone, obs, price, iva, comission, wayToPay, version) => { 
    const url = `${API_URL}/item/offer`
    
    
    return (dispatch) => {
      let outputData = {};
      let params = {
          data: {
            id: id,
            numerointerno: numerointerno,
            name: name,
            rut: rut,
            email: email, 
            phone: phone, 
            obs: obs,
            price:price,
            iva: iva,
            comission,
            wayToPay: wayToPay,
            version: version,
          }
        
      };
      console.log(params);
      
      Axios.put(url, params).then(({ data }) => {
        if (data.error) {
          outputData['status'] = 'danger';
          outputData['message'] = 'Error creating form';
          dispatch(setUpdateOffer(outputData));
         
        }
        else {
  
          console.log(data);
          
          outputData['data'] = data.url;
          outputData['status'] = data.status;
          outputData['message'] = data.message;
          dispatch(setUpdateOffer(outputData));
        }
      })
    }
  };
  
  export const setUpdateOffer = (data) => ({
      type: types.updateOffer,
      payload: data,
  });



export const exportOffer = () => {
  const url = `${API_URL}/item/offer/export`
  
  return (dispatch) => {
    let outputData = {};

    Axios.get(url, { responseType: 'blob' }).then(({ data }) => {
      if (data.error) {
        outputData['status'] = 'danger';
        outputData['message'] = 'Error creating form';
        dispatch(setExportOffer(outputData));
      }
      else {
          //download data blob xlsx
          const url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));

          const link = document.createElement('a');
          link.href = url;
          const date = new Date();
          link.setAttribute('download', `Ofertas_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          outputData['data'] = data.data;
          outputData['status'] = data.status;
          outputData['message'] = data.message;
          dispatch(setExportOffer(outputData));
      }
    })
  }
};

export const setExportOffer = (data) => ({
    type: types.exportOffer,
    payload: data,
});
