import { types } from '../actions/types';

export const quotationReducer = (state = { data: null ,create: null, update: null, get: null, export: null }, action) => {
    switch (action.type) {
        case types.createQuotation:
            return {
              ...state,
              create: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,
              } 
            };
        case types.updateQuotation:
            return {
                ...state,
                update: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,
                } 
            };
        case types.getQuotation:
            return {
                ...state,
                get: {
                    status: action.payload.status,
                    message: action.payload.message,
                    data: action.payload.data,  
                } 
            };
        case types.exportQuotation:
            return {
                ...state,
                export: {
                status: action.payload.status,
                message: action.payload.message,
                data: action.payload.data,
                } 
            };
        default:
            return state;
    }
}