import React, { useEffect, useState } from "react";
import { API_URL } from "../../redux/actions/types";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Spinner,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
  Row,
  Col,
} from "reactstrap";
import {
  getProvider,
  getCategories,
  createReport,
} from "../../redux/actions/provider";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function Report() {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.provider.providers);
  const categories = useSelector((state) => state.provider.categories);
  const [loading, setLoading] = useState(true);
  const [loadingC, setLoadingC] = useState(true);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [mandante, setmandante] = useState("");
  const [states, setStates] = useState([]);
  const [categoria, setcategoria] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  

  useEffect(() => {
    if (providers) {
      if (providers.message === "Providers") {

        setLoading(false);
      }
    }
  }, [providers]);

  useEffect(() => {
    if (categories) {
      if (categories.message === "Categories") {
        setLoadingC(false);
      }
    }
  }, [categories]);

  useEffect(() => {
    document.title = "BGL - Crear Reporte";
    dispatch(getCategories());
    dispatch(getProvider());
  }, []);

  const onDismiss = () => setShowAlert(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(createReport(start, end, mandante, categoria, states));
  };

  const statusList = (status) => {
    let aux = states;
    if (aux.includes(status)) {
      aux = aux.filter((item) => item !== status);
    } else {
      aux = aux.concat(status);
    }
    setStates(aux);
  };

  const refresh =() => {
    console.log("sdg");
    setEnd("");
    setcategoria("");
    setmandante("");
    setStart("");
    setStates([]);
    
  }

  return loading && loadingC ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Alert isOpen={showAlert} toggle={onDismiss}>
          {alertTitle} - {alertMessage}
        </Alert>
      </Container>
      <Container
        className={"p-3 container-border"}
        style={{ marginTop: "10px" }}
      >
        <h2 style={{ textAlign: "center" }}>REPORTES</h2>
        <Form onSubmit={(e) => onFormSubmit(e)}>
          <Row>
            <Col lg='4'></Col>
            <Col sm='12' lg='12'>
              <FormGroup>
                <Label for='exampleDate'>Fecha de Inicio</Label>
                <Input
                  type='date'
                  name='date'
                  id='exampleDate'
                  placeholder='date placeholder'
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for='exampleDate'>Fecha de Fin</Label>
                <Input
                  type='date'
                  name='date'
                  id='exampleDate'
                  placeholder='date placeholder'
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                />
              </FormGroup>
              <FormGroup className='col-md-12 float-left'>
                <Row className='col-md-12'>
                  <Label column='true' sm='4'>
                    Mandante
                  </Label>
                  <Col sm='8' className='float-right'>
                    <Input
                      type='select'
                      onChange={(e) =>
                        setmandante(e.target.value)
                      }
                      value={mandante}
                    >
                      <option value={""}></option>
                      {providers &&
                        providers.data.map((provider, index) =>
                          provider.name !== mandante.value ||
                          mandante.value !== "" ? (
                            <option key={index} value={provider.name}>
                              {provider.name}
                            </option>
                          ) : (
                            <option key={index} selected value={mandante.value}>
                              {mandante.value}
                            </option>
                          )
                        )}
                    </Input>
                  </Col>
                </Row>
                <Row className='col-md-12 mt-2'>
                  <Label column='true' sm='4'>
                    Categoria del Bien
                  </Label>
                  <Col lg='8' className='float-right'>
                    <Input
                      type='select'
                      onChange={(e) =>
                        setcategoria(e.target.value)
                      }
                      value={categoria}
                    >
                      <option value={""}></option>
                      {categories &&
                        categories.data.data.map((category, index) =>
                          category.category_name !== categoria.value ? (
                            <option key={index} value={category.category_name}>
                              {category.category_name}
                            </option>
                          ) : (
                            <option
                              key={index}
                              selected
                              value={categoria.value}
                            >
                              {categoria.value}
                            </option>
                          )
                        )}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col lg='4'></Col>
          </Row>
          <Row className='ml-3 mt-3 pb-3'>
            <Col lg='3'>
              <Label check>
                <Input
                  type='checkbox'
                  value={states.filter(state => state === "No Liberado" ) ? false: true}
                  onChange={(e) => statusList("No Liberado")}
                />{" "}
                No Liberados
              </Label>
            </Col>
            <Col lg='3'>
              <Label check>
                <Input
                  type='checkbox'
                  onChange={(e) => statusList("Liberado")}
                />{" "}
                Liberados
              </Label>
            </Col>
            <Col lg='3'>
              <Label check>
                <Input
                  type='checkbox'
                  onChange={(e) => statusList("Vendido")}
                />{" "}
                Vendidos
              </Label>
            </Col>
            <Col lg='3'>
              <Label check>
                <Input
                  type='checkbox'
                  onChange={(e) => statusList("Devuelto")}
                />{" "}
                Devueltos
              </Label>
            </Col>
          </Row>
          <Button className="btn btn-info float-right" onClick={()=> refresh()}>REFRESCAR</Button>
          <a
            className='btn btn-primary'
            target='_blank'
            href={
              API_URL +
              `/reports?start=${start}&end=${end}&category=${
                categoria
              }&provider=${mandante}&states=${states.toString()}`
            }
          >
            GENERAR EXCEL
          </a>
        </Form>
      </Container>
    </>
  );
}
export default Report;
